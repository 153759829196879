import React, { Fragment, useMemo, cloneElement, useEffect } from 'react'
import { capitalCase } from 'change-case'
import {
  List,
  Filter,
  TextField,
  DateField,
  ShowButton,
  // ChipField,
  ReferenceField,
  ReferenceArrayField,
  Datagrid,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Resource,
  EditButton,
  FunctionField,
  CardActions,
  ArrayField,
  SingleFieldList,
  TabbedShowLayout,
  Tab,
  ChipField,
  BooleanInput,
  DateTimeInput,
  ExportButton,
  CreateButton,
  ListActions,
  Toolbar,
} from 'react-admin'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import SectionTitle from '../components/SectionTitle'
import TabbedView from '../components/TabbedView'
import BooleanField from '../components/BooleanField'
import CustomChipField from '../components/ChipField'
import states from '../lib/states'
import * as storage from '../lib/storage'

const useStyles = makeStyles((theme) => ({
  title: {
    width: 500,
  },
  textInput: {
    width: 500,
  },
  search: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  list: {
    '& [class*="RaEmpty"] a[aria-label="Create"]': {
      marginBottom: 20,
    },
  },
}))

const ListFilter = (props) => {
  const classes = useStyles()

  return (
    <Filter {...props}>
      <TextInput variant="outlined" label="Search" source="q" alwaysOn className={classes.search} />
    </Filter>
  )
}

const getUserList = (accountId) => (props) => {
  return (
    <List
      filters={<ListFilter />}
      {...props}
      filter={{ accountId }}
      hasCreate
      basePath={'/users'}
      actions={<ListActions basePath="/users" />}
    >
      <Datagrid rowClick={(id, basePath) => `${basePath}/users/${id}`}>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="phone" />
        <FunctionField label="Role" render={(data) => capitalCase(data.role)} />
        <EditButton basePath="/users" />
      </Datagrid>
    </List>
  )
}

const getModalList = (accountId) => (props) => {
  return (
    <List
      filters={<ListFilter />}
      {...props}
      hasCreate
      basePath={'/modalities'}
      filter={{ accountId }}
      actions={<ListActions basePath="/modalities" />}
    >
      <Datagrid rowClick={(id, basePath) => `${basePath}/modalities/${id}`}>
        <TextField source="id" />
        <TextField source="siteName" />
        <TextField source="serialNumber" />
        <TextField source="manufacturer" />
        <TextField source="modality" />
        <TextField source="model" />
        <TextField source="siteIdNumber" />
        <TextField source="siteAddress" />
        <EditButton basePath="/modalities" />
      </Datagrid>
    </List>
  )
}

const getReportList = (accountId) => (props) => {
  const classes = useStyles()
  return (
    <List
      {...props}
      hasCreate
      basePath={'/reports'}
      filter={{ accountId }}
      filters={<ListFilter />}
      actions={<ListActions className={classes.search} />}
      className={classes.list}
    >
      <Datagrid rowClick={(id, basePath) => `${basePath}/${id}`}>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="interval" />
        <CustomChipField source="recipients" sortable={false} />
        <DateField label="Next Run" showTime source="nextRunAt" />
        <BooleanField source="recurrent" />
        <BooleanField source="active" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const getAlertList = (accountId) => (props) => {
  const classes = useStyles()
  return (
    <List
      {...props}
      hasCreate
      filter={{ accountId }}
      filters={<ListFilter />}
      actions={<ListActions className={classes.search} />}
      basePath={'/alerts'}
      className={classes.list}
    >
      <Datagrid rowClick={(id, basePath) => `${basePath}/${id}`}>
        <TextField source="id" />
        <TextField source="title" />

        <ReferenceArrayField reference="users" source="userIds" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>

        <BooleanField source="active" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const ActionsRefresh = (props) => <CardActions />

const AccountEdit = ({ permissions, ...props }) => {
  const classes = useStyles()
  const UserList = useMemo(() => getUserList(props.id), [props.id])
  const ModalList = useMemo(() => getModalList(props.id), [props.id])
  const ReportList = useMemo(() => getReportList(props.id), [props.id])
  const AlertList = useMemo(() => getAlertList(props.id), [props.id])

  useEffect(() => {
    if (props.id) {
      storage.set('accountId', props.id)
    }
  }, [props.id])

  return (
    <Fragment>
      <br />

      <Paper>
        <TabbedView
          tabLabels={['Account Details', 'Reports', 'Alerts']}
          tabsProps={{ centered: false }}
          headerProps={{ style: { backgroundColor: 'white' } }}
        >
          <Box>
            <Edit {...props} actions={<ActionsRefresh />}>
              <SimpleForm>
                <TextInput source="name" formClassName={classes.textInput} fullWidth />
                <TextInput source="address1" formClassName={classes.textInput} fullWidth />
                <TextInput source="address2" formClassName={classes.textInput} fullWidth />
                <TextInput source="city" formClassName={classes.textInput} fullWidth />
                <SelectInput
                  fullWidth
                  label="State"
                  source="state"
                  formClassName={classes.textInput}
                  choices={states}
                />
                <TextInput source="zip" formClassName={classes.textInput} fullWidth />
              </SimpleForm>
            </Edit>
          </Box>
          <Box>
            <Resource name="reports" list={ReportList} />
          </Box>
          <Box>
            <Resource name="alerts" list={AlertList} />
          </Box>
        </TabbedView>
      </Paper>
      <br />
      <br />

      <SectionTitle label="Account users" />
      <Resource name="users" list={UserList} />

      <br />
      <br />

      <SectionTitle label="Account modalities" />
      <Resource name="modalities" list={ModalList} />

      <br />
      <br />
    </Fragment>
  )
}

export default AccountEdit
