import React from 'react'
import { Route } from 'react-router-dom'
import ModalityDashboard from './dashboards/modality/TempSample'
import ModalityTubeAnalysis from './modalities-tube-analysis/List'
import ModalityMap from './modality-map'
import Utilization from './utilization'
import Financial from './financial'

export default [
  <Route exact path="/modalities/dashboard" component={ModalityDashboard} />,

  // <Route exact path="/modalities/tube-analysis" component={ModalityTubeAnalysis} />,

  <Route exact path="/modalities/map" component={ModalityMap.map} />,
  <Route exact path="/utilization" component={Utilization.home} />,
  <Route exact path="/financial" component={Financial.home} />,
]
