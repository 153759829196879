import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 8,
    height: 8,
    borderRadius: 8,
  },
}))

const Badge = ({ color }) => {
  const classes = useStyles()

  return <div className={classes.root} style={{ backgroundColor: color }} />
}

export default Badge
