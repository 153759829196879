import React from 'react'
import { capitalCase } from 'change-case'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  EditButton,
  FunctionField,
} from 'react-admin'

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const UserList = (props) => (
  <List filters={<UserFilter />} {...props}>
    <Datagrid rowClick="edit">
      <EditButton />
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="phone" />
      <ReferenceField label="Account" source="accountId" reference="accounts">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField label="Role" render={(data) => capitalCase(data.role)} />
    </Datagrid>
  </List>
)

export default UserList
