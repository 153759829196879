import React from 'react'
import Indicator from './Indicator'

const DANGER = '#ef5350'
const GOOD = '#2cbe4e'
const WARNING = '#ffd600'
const INDETERMINATE = '#e0e0e0'

const status = {
  green: GOOD,
  yellow: WARNING,
  red: DANGER,
  gray: INDETERMINATE,
}

const getHealthStatus = (data = {}) => {
  return status[data.healthStatusColor] || INDETERMINATE
}

const getTimeStatus = (data) => {
  return status[data.timeStatusColor] || INDETERMINATE
}

const ModalityStatusIndicator = ({ time, data, ...props }) => {
  const color = time ? getTimeStatus(data) : getHealthStatus(data)

  return <Indicator {...props} color={color} />
}

export default ModalityStatusIndicator
