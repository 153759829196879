import React from 'react'
// import TextField from '@material-ui/core/TextField'
import BaseChipInput from 'material-ui-chip-input'
import { useField } from 'react-final-form'

const ChipInput = ({ name, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(name)

  console.log('PROPS', props)

  return (
    <BaseChipInput
      //   defaultValue={['foo', 'bar']}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      defaultValue={props.record[name]}
      {...props}
      fullWidth
      fullWidthInput
      variant="filled"
    />
  )
}

export default ChipInput
