
import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone'
import { capitalCase } from 'change-case'
import {
  useMutation,
  useQuery,
} from 'react-admin'
import { set, get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import RangeCell from '../../components/RangeCell'

const styles = { table: { minWidth: 650 }}
const useStyles = makeStyles(styles)

const format = (data) => {
  if (!data) {
    return {}
  }

  let res = {}
  const array = Object.entries(data).reduce((acc, [key, item]) => {
    acc.push({ ...item, key })
    return acc
  }, [])

  array.reduce((acc, { key, ...item }) => {
    Object.entries(item).forEach(([k, v]) => {
      // acc[key] = { levels: { } }
      set(res, `${k}.levels.${key}`, v)
      // set(res, `${k}.name`, )
    })
    return acc
  }, {})

  return res
}

export const ThresholdPanel = ({ id }) => {
  const classes = useStyles()
  const { data: item } = useQuery({
    type: 'getOne',
    resource: 'modalities/settings',
    payload: { id },
  })

  const { thresholds } = item?.settings || {}
  let data = {}

  if (item) {
    data = format(item.settings.thresholds)
  }

  const [mutate, { loading }] = useMutation()

  const update = (data) =>
    mutate({
      type: 'update',
      resource: `modalities/settings`,
      payload: { id, data },
    })


  const onUpdate = ([level, key, value]) => {
    update({
      thresholds: {
        ...thresholds,
        [level]: {
          ...thresholds[level],
          [key]: value.map(Number),
        },
      },
    })
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <span>Metric</span>
              </TableCell>
              <TableCell align="left">
                <span>
                  Green{' '}
                  <FiberManualRecordTwoToneIcon
                    fontSize="small"
                    style={{ marginBottom: -6, color: '#2cbe4e' }}
                  />
                </span>
              </TableCell>
              <TableCell align="left">
                <span>
                  Yellow{' '}
                  <FiberManualRecordTwoToneIcon
                    fontSize="small"
                    style={{ marginBottom: -6, color: '#ffd600' }}
                  />
                </span>
              </TableCell>
              <TableCell align="left">
                <span>
                  Red{' '}
                  <FiberManualRecordTwoToneIcon
                    fontSize="small"
                    style={{ marginBottom: -6, color: '#ef5350' }}
                  />
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([key, item]) => {
              return (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {capitalCase(key)}
                  </TableCell>
                  {item.levels.green && (
                    <TableCell align="right">
                      <RangeCell
                        onChange={(range) => onUpdate(['green', key, range])}
                        range={get(data, `${key}.levels.green`)}
                      />
                    </TableCell>
                  )}
                  {item.levels.yellow && (
                    <TableCell align="right">
                      <RangeCell
                        onChange={(range) => onUpdate(['yellow', key, range])}
                        range={get(data, `${key}.levels.yellow`)}
                      />
                    </TableCell>
                  )}
                  {item.levels.red && (
                    <TableCell align="right">
                      <RangeCell
                        onChange={(range) => onUpdate(['red', key, range])}
                        range={get(data, `${key}.levels.red`)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}