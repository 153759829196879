import React, { useState } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  ReferenceInput,
  SelectInput,
  ChipField,
  SelectArrayInput,
  ReferenceArrayInput
} from 'react-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import TabbedView from '../components/TabbedView'
import SectionTitle from '../components/SectionTitle'
import { useUser } from '../hooks'
import { PartsPanel, ThresholdPanel } from './components'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 },
  latitude: { display: 'inline-block', width: 237 },
  longitude: { display: 'inline-block', width: 237, marginLeft: 25 }
}
const useStyles = makeStyles(styles)

const tabs = ['general', 'thresholds', 'parts', 'maintenance']

const ModalityEdit = props => {
  const classes = useStyles()
  const { isSuperadmin } = useUser()
  const [oldKeyVisible, setOldKeyVisibility] = useState(false)
  const [newKeyVisible, setNewKeyVisibility] = useState(false)
  const activeTab = props.location.pathname.split('/')?.[3]
  const tabIndex = activeTab && tabs.findIndex(i => i === activeTab)

  const onToggleOldKeyVisibility = () => {
    setOldKeyVisibility(v => !v)
  }

  const onToggleNewKeyVisibility = () => {
    setNewKeyVisibility(v => !v)
  }

  return (
    <Paper style={{ marginTop: 24 }}>
      <TabbedView
        tabLabels={tabs}
        tabsProps={{ centered: false }}
        headerProps={{ style: { backgroundColor: 'white' } }}
        activeTab={tabIndex}
      >
        <Box key='tab-general'>
          <Edit {...props} actions={false}>
            <SimpleForm>
              <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <SectionTitle label='Site Information' />
                      <TextInput
                        source='siteName'
                        formClassName={classes.textInput}
                        fullWidth
                      />
                      <TextInput
                        source='siteIdNumber'
                        formClassName={classes.textInput}
                        fullWidth
                      />
                      <TextInput
                        source='siteAddress'
                        formClassName={classes.textInput}
                        fullWidth
                      />
                      <TextInput
                        source='latitude'
                        formClassName={classes.latitude}
                        fullWidth
                      />
                      <TextInput
                        source='longitude'
                        formClassName={classes.longitude}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SectionTitle label='Modality Information' />
                      <ReferenceInput
                        fullWidth
                        source='manufacturerId'
                        reference='manufacturers'
                        formClassName={classes.textInput}
                      >
                        <SelectInput source='manufacturer' />
                      </ReferenceInput>
                      <ReferenceInput
                        fullWidth
                        source='typeId'
                        reference='modalities/types'
                        formClassName={classes.textInput}
                      >
                        <SelectInput source='type' />
                      </ReferenceInput>
                      <TextInput
                        source='model'
                        formClassName={classes.textInput}
                        fullWidth
                      />
                      <TextInput
                        source='serialNumber'
                        formClassName={classes.textInput}
                        fullWidth
                      />
                      <SelectInput
                        source='active'
                        choices={[
                          { id: 1, name: 'Yes' },
                          { id: 0, name: 'No' }
                        ]}
                        formClassName={classes.textInput}
                        fullWidth
                      />
                      <TextInput
                        source='dashboardUrl'
                        formClassName={classes.textInput}
                        fullWidth
                        multiline
                        disabled={!isSuperadmin()}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <SectionTitle label='Accounts' />
                      {isSuperadmin() && (
                        <ReferenceArrayInput
                          fullWidth
                          reference='accounts'
                          source='accountIds'
                          formClassName={classes.textInput}
                        >
                          <SelectArrayInput label='Accounts' optionText='name'>
                            <ChipField source='name' />
                          </SelectArrayInput>
                        </ReferenceArrayInput>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SectionTitle label='Credentials' />
                      <TextInput
                        label='Flex ID'
                        source='credentials.flexId'
                        formClassName={classes.textInput}
                        fullWidth
                      />

                      <TextInput
                        fullWidth
                        type={oldKeyVisible ? 'text' : 'password'}
                        label='Login'
                        source='credentials.username'
                        formClassName={classes.textInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle key visibility'
                                onClick={onToggleOldKeyVisibility}
                              >
                                {oldKeyVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <TextInput
                        type={newKeyVisible ? 'text' : 'password'}
                        fullWidth
                        label='Key'
                        source='credentials.password'
                        formClassName={classes.textInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle key visibility'
                                onClick={onToggleNewKeyVisibility}
                              >
                                {newKeyVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />

                      <TextInput
                        inputProps={{
                          readOnly: true,
                          style: { textTransform: 'capitalize' }
                        }}
                        label='Status'
                        source='credentials.status'
                        formClassName={classes.textInput}
                        fullWidth
                      />

                      <TextInput
                        multiline
                        rows={4}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ readOnly: true }}
                        label='Last error message'
                        source='credentials.message'
                        formClassName={classes.textInput}
                        fullWidth
                        placeholder='No errors'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SimpleForm>
          </Edit>
        </Box>
        <Box key='tab-threshold'>
          <ThresholdPanel {...props} />
        </Box>
        <Box key='tab-parts'>
          <PartsPanel {...props} />
        </Box>
      </TabbedView>
    </Paper>
  )
}

export default ModalityEdit
