import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import useDashboard from '../hooks/useDashboard'
import { dashboards } from '../data'

const Financial = () => {
  const [dashboardOptions, setDashboardOptions] = useState([])
  const [selectedDashboard, setSelectedDashboard] = useState()
  const embedUrl = useDashboard({ id: selectedDashboard })
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await dashboards.fetch()
        const serviceDashboards = list.filter(i => i.type === 'SERVICE')
        setDashboardOptions(serviceDashboards)
        setSelectedDashboard(serviceDashboards[0].address)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  return (
    <>
      <FormControl variant="filled" style={{ minWidth: 120 }}>
        <InputLabel id="demo-simple-select-filled-label">
          Customer
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectedDashboard}
          onChange={v => setSelectedDashboard(v)}
        >
          {dashboardOptions.map(i =>
            <MenuItem value={i.address}>
              {i.name}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Container>
        {!embedUrl ? (
          <CircularProgress />
        ) : (
          <div id="dashboardContainer" style={{ width: '100%', height: '100%' }} />
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 60px);
  align-items: center;
  justify-content: center;
  font-family: Roboto, arial, sans-serif;

  .embed-chrome-container {
    display: none;
  }
`

export default Financial
