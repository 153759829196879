import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)

const DonutChart = ({ id, title, data }) => {
  useEffect(() => {
    let chart = am4core.create(id, am4charts.PieChart)

    chart.padding(10, 10, 10, 10)
    chart.radius = am4core.percent(100)
    chart.innerRadius = am4core.percent(70)
    chart.data = data

    chart.fontSize = 12

    var pieSeries = chart.series.push(new am4charts.PieSeries())
    pieSeries.dataFields.value = 'value'
    pieSeries.dataFields.category = 'name'
    pieSeries.slices.template.stroke = am4core.color('#fff')
    pieSeries.slices.template.strokeWidth = 2
    pieSeries.slices.template.strokeOpacity = 1

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1
    chart.legend = new am4charts.Legend()
    chart.legend.marginTop = 20
    pieSeries.labels.template.disabled = true
    pieSeries.legendSettings.labelText = '{category}: {value}'
    chart.legend.valueLabels.template.disabled = true
    am4core.addLicense('ch-custom-attribution')
  }, [data])

  return (
    <Card>
      {data.length === 0 && <LinearProgress />}
      <CardContent>
        <div style={{ width: '100%', height: '100%', paddingTop: 0 }}>
          <Typography align="center">{title}</Typography>
          <div id={id} style={{ width: '100%', height: 250, marginTop: 14 }}></div>
        </div>
      </CardContent>
    </Card>
  )
}

export default DonutChart
