import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

import useDashboard from '../hooks/useDashboard'

// const UTILIZATION_DASH = '66519528-3f05-454b-b0b1-95e7f9de4bd6'

const Utilization = () => {
  // const embedUrl = useDashboard({ id: UTILIZATION_DASH })
  const embedUrl = useDashboard({ type: 'UTILIZATION' })

  return (
    <Container>
      {!embedUrl ? (
        <CircularProgress />
      ) : (
        <div id="dashboardContainer" style={{ width: '100%', height: '100%' }} />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 60px);
  align-items: center;
  justify-content: center;
  font-family: Roboto, arial, sans-serif;
`

export default Utilization
