import React, { Fragment, useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone'
import Slider from '@material-ui/core/Slider'
import Popover from '@material-ui/core/Popover'
import EditIcon from '@material-ui/icons/Edit'
import TextField from '@material-ui/core/TextField'
import { toFixedNumber } from '../lib/utils'

const RangeCell = ({ range: defaultRange = [0, 0], ...props }) => {
  const [range, setRange] = useState(defaultRange)
  const [anchorEl, setAnchorEl] = useState(null)
  const [min, setMin] = useState(range[0])
  const [max, setMax] = useState(range[1])
  const [showEdit, setShowEdit] = useState(false)

  const [a, b] = range
  const open = Boolean(anchorEl)

  const onOpen = useCallback(e => {
    setAnchorEl(e.currentTarget)
  }, [])

  const onClose = () => {
    setAnchorEl(null)
  }

  const onChange = (min, max) => {
    if (min || min === 0) setMin(Number(min))
    if (max) setMax(Number(max))
  }

  const onSave = value => {
    props.onChange(value)
    setRange(value)
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Box
        onClick={onOpen}
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
        width='100%'
        display='flex'
        justifyContent='flex-start'
      >
        <div>[ {toFixedNumber(a, 2)}</div>
        <div style={{ marginLeft: 4, marginRight: 4 }}> - </div>
        <div>{toFixedNumber(b, 2)} ]</div>
        <div style={{ width: 20 }}>
          {showEdit && <EditIcon style={{ fontSize: 14 }} />}
        </div>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box p={2} pb={4} width={300} height={80}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <div>
              <TextField
                id='range-min'
                margin='dense'
                variant='outlined'
                value={min}
                onChange={e => onChange(e.target.value)}
                style={{ width: 100, marginRight: 8 }}
                inputProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                type='number'
              />

              <TextField
                id='range-max'
                margin='dense'
                variant='outlined'
                value={max}
                onChange={e => onChange(null, e.target.value)}
                style={{ width: 100 }}
                inputProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                type='number'
              />
            </div>

            <div>
              <Button
                style={{ padding: 4, marginTop: 2 }}
                variant='contained'
                color='primary'
                onClick={() => onSave([min, max])}
              >
                <CheckTwoToneIcon />
              </Button>
            </div>
          </Box>

          <Box
            mt={2}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            style={{ flexGrow: 0 }}
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <RemoveCircleOutlineIcon
                color='primary'
                onClick={() => onChange(toFixedNumber(min - 0.01, 2))}
              />
              <AddCircleOutlineIcon
                color='primary'
                onClick={() => onChange(toFixedNumber(min + 0.01, 2))}
              />
            </Box>
            <Slider
              style={{ flexGrow: 2, marginLeft: 16, marginRight: 16 }}
              min={-100}
              max={100}
              step={0.1}
              // scale={(x) => x ** 10}
              value={[min, max]}
              onChange={(_, range) => onChange(...range)}
            />
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              flexGrow={0}
            >
              <RemoveCircleOutlineIcon
                color='primary'
                onClick={() => onChange(null, toFixedNumber(max - 0.01, 2))}
              />
              <AddCircleOutlineIcon
                color='primary'
                onClick={() => onChange(null, toFixedNumber(max + 0.01, 2))}
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </Fragment>
  )
}

RangeCell.defautlProps = {
  range: [0, 0],
  onChange: () => {}
}

export default RangeCell
