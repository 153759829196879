import { get } from '../../lib/api'

const fetch = async (id = {}) => {
  const { data } =
    typeof id === 'string' ? await get(`modalities/${id}`) : await get('modalities', id)
  return data
}

const fetchUsageHistory = async (id) => {
  const { data } = await get(`modalities/usage-history/${id}`)
  return data
}

const fetchCountByVendorAndType = async () => {
  const { data } = await get('modalities/count-by-vendor')
  return data
}

export default {
  fetch,
  fetchUsageHistory,
  fetchCountByVendorAndType,
}
