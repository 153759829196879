import React, { Fragment } from 'react'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  ShowButton,
  EditButton,
  downloadCSV,
  FunctionField,
} from 'react-admin'

import jsonExport from 'jsonexport/dist'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import HealthIndicator from '../components/HealthIndicator'
import TimeIndicator from '../components/TimeIndicator'

const useStyles = makeStyles({
  textInput: { width: 400, marginRight: 10 },
  selectInput: { width: 200 },
  field: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const exporter = (modalities) => {
  const modalitiesForExport = modalities.map((modality) => {
    const {
      dashboardIframeUrl,
      logstreamIframeUrl,
      relationshipId,
      metrics,
      relationship,
      ...modalityForExport
    } = modality
    return modalityForExport
  })
  jsonExport(
    modalitiesForExport,
    {
      headers: [
        'id',
        'serialNumber',
        'siteIdNumber',
        'manufacturer',
        'modality',
        'model',
        'siteName',
        'heLevel',
        'heLevelTime',
        'siteAddress',
        'latitude',
        'longitude',
        'softwareVersion',
        'createdDate',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'modalities')
    }
  )
}

const ModalityFilter = (props) => {
  const classes = useStyles()

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn className={classes.textInput} />
      <ReferenceInput label="Modality Type" source="type" reference="modalities/types" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="Filter Active/Inactive"
        source="active"
        alwaysOn
        className={classes.selectInput}
        choices={[
          { id: 1, name: 'Only Active' },
          { id: 0, name: 'Only Inactive' },
          { id: 2, name: 'Show All' },
        ]}
      />
    </Filter>
  )
}

const ModalityPanel = ({ id, record, resource }) => (
  <Box
    my={2}
    mx={2}
    display="flex"
    alignItems="center"
    justifyContent="flex-start"
    flexDirection="row"
  >
    <Box mx={4}>
      <Typography variant="body2" color="textSecondary">
        Location
      </Typography>{' '}
      <Typography>{record.siteAddress}</Typography>
    </Box>
    {record.credentials && record.manufacturer === 'Siemens' && (
      <Fragment>
        {record.credentials.username && (
          <Box mx={4}>
            <Typography variant="body2" color="textSecondary">
              Login
            </Typography>{' '}
            <Typography>{record.credentials.username}</Typography>
          </Box>
        )}
        {record.credentials.password && (
          <Box mx={4}>
            <Typography variant="body2" color="textSecondary">
              Key
            </Typography>{' '}
            <Typography>{record.credentials.password}</Typography>
          </Box>
        )}
        {record.credentials.expiration && (
          <Box mx={4}>
            <Typography variant="body2" color="textSecondary">
              Expiration
            </Typography>{' '}
            <Typography>{record.credentials.expiration}</Typography>
          </Box>
        )}
        {record.credentials.flexId && (
          <Box mx={4}>
            <Typography variant="body2" color="textSecondary">
              Flex ID
            </Typography>{' '}
            <Typography>{record.credentials.flexId}</Typography>
          </Box>
        )}
      </Fragment>
    )}
  </Box>
)

const Actions = (data) => {
  const classes = useStyles()

  return (
    <div className={classes.actions}>
      <ShowButton record={data} label="" basePath="/modalities" />
      <EditButton record={data} label="" basePath="/modalities" />
    </div>
  )
}

const ModalityList = (props) => {
  return (
    <List filters={<ModalityFilter />} exporter={exporter} bulkActionButtons={false} {...props}>
      <Datagrid size="small" rowClick="show" expand={<ModalityPanel />}>
        <FunctionField
          label="Health"
          sortBy="healthStatusLevel"
          render={(props) => <HealthIndicator {...props} />}
        />
        <FunctionField
          label="Time"
          sortBy="timeStatusLevel"
          render={(props) => <TimeIndicator {...props} />}
        />
        <TextField source="id" />
        <TextField source="siteName" />
        <TextField source="serialNumber" />
        <TextField source="manufacturer" />
        <TextField source="modality" />
        <TextField source="model" />
        <FunctionField
          label="Metrics"
          render={(props) => (
            <div>
              {props.timeStatusLevel !== 2 ? (
                props.metrics
              ) : (
                <span style={{ opacity: 0.5, fontStyle: 'italic' }}>
                  Telemetry data is >48 hrs old on this device
                </span>
              )}
            </div>
          )}
        />
        <FunctionField label="Actions" render={(props) => <Actions {...props} />} />
      </Datagrid>
    </List>
  )
}

export default ModalityList
