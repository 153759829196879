import React from 'react'
import { Layout } from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'
import { useBrand } from '../hooks/useBrand'

export default props => {
  const brand = useBrand()

  return <Layout {...props} appBar={AppBar} theme={brand.theme} menu={Menu} />
}
