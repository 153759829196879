import AccountIcon from '@material-ui/icons/Business'
import List from './List'
import Create from './Create'
import Edit from './Edit'

export default {
  list: List,
  edit: Edit,
  show: Edit,
  create: Create,
  icon: AccountIcon,
}
