import React from 'react'
import { isNumber } from 'lodash'
import numbro from 'numbro'
import { capitalCase } from 'change-case'
import ListBase from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import ModalityStatusIndicator from './ModalityStatusIndicator'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from './Tooltip'
import Badge from './Badge'

const DANGER = '#ef5350'
const GOOD = '#2cbe4e'
const WARNING = '#ffd600'
const INDETERMINATE = '#e0e0e0'

const status = {
  green: GOOD,
  yellow: WARNING,
  red: DANGER,
  gray: INDETERMINATE,
}

const useStyles = makeStyles({
  textInput: { width: 400, marginRight: 10 },
  selectInput: { width: 200 },
  field: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const getStatusText = (data) => {
  if (data.timeStatusColor === 'yellow' || data.timeStatusColor === 'red') {
    const time = data[data.timeStatusKeys]
    const relativeTime = moment(time, 'M/DD/YYYY h:mm a').fromNow(true)

    return `Inactive for ${relativeTime}`
  }

  return `Last report time ${data[data.timeStatusKeys]}`
}

const IndicatorItem = ({ field = '', timeField = '', data = {}, color, isTime = false }) => {
  return (
    <ListItem style={{ justifyContent: 'flex-start' }} dense>
      <ListItemIcon>
        <Badge color={color} />
      </ListItemIcon>
      <ListItemText primary={capitalCase(timeField || field)} style={{ marginLeft: -32 }} />
      <ListItemSecondaryAction style={{ color: '#444', fontWeight: '600' }}>
        {!isTime && isNumber(data[field])
          ? numbro(Number(data[field])).format({
              thousandSeparated: true,
              mantissa: 0,
            })
          : data[field]}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const TimeIndicator = (data) => {
  const classes = useStyles()

  return (
    <Tooltip
      disableFocusListener
      title={
        <div style={{ width: 280 }}>
          {getStatusText(data)}
          <ListBase
            className={classes.root}
            style={{
              backgroundColor: '#fff',
              borderRadius: 4,
              marginBottom: 8,
              marginTop: 8,
              color: '#999',
              fontSize: 14,
            }}
          >
            <IndicatorItem
              isTime
              data={data}
              color={status[data.timeStatusColor]}
              field={data.timeStatusKeys || ''}
              timeField="Last Time"
            />
          </ListBase>
        </div>
      }
      arrow
    >
      <div className={classes.indicator}>
        <ModalityStatusIndicator time data={data} />
      </div>
    </Tooltip>
  )
}

export default TimeIndicator
