import React, { Fragment, useState, useEffect, cloneElement } from 'react'
import numbro from 'numbro'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  downloadCSV,
  useMutation,
  FunctionField,
} from 'react-admin'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import jsonExport from 'jsonexport/dist'
import MuiList from '@material-ui/core/List'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CardContent from '@material-ui/core/CardContent'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SectionTitle from '../components/SectionTitle'
import BarChart from '../components/BarChart'
import UsageChart from '../components/UsageChart'
import Tooltip from '../components/Tooltip'
import Data from '../components/Data'
import { useFilter, FilterProvider } from '../hooks/useFilter'
import { formatHistoryData } from '../lib/utils'
import * as api from '../data'

const useStyles = makeStyles((theme) => ({
  textInput: { width: 400, marginRight: 10 },
  selectInput: { width: 200 },
  field: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltipContent: {
    backgroundColor: '#fff',
    borderRadius: 4,
    paddingBottom: 16,
    marginBottom: 8,
    marginTop: 8,
    minHeight: 200,
    color: '#999',
    fontSize: 14,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },

  tooltipContentSmall: {
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 8,
    marginTop: 8,
    minHeight: 100,
    color: '#666',
    fontSize: 14,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  bold: {
    fontWeight: '500',
    color: theme.palette.secondary.main,
  },
  MuiAccordionRoot: {
    '&:before': {
      display: 'none',
    },
  },
}))

const exporter = (modalities) => {
  const modalitiesForExport = modalities.map((modality) => {
    const {
      dashboardIframeUrl,
      logstreamIframeUrl,
      relationshipId,
      metrics,
      relationship,
      ...modalityForExport
    } = modality
    return modalityForExport
  })

  jsonExport(
    modalitiesForExport,
    {
      headers: [
        'id',
        'displayName',
        'tube',
        'tubeScanSecs',
        'tubeScanSecsUsed',
        'tubeExpectedLife',
        'timeToFailure',
        'dateToFailure',
        'tubeCost',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'modalities-tube-analysis')
    }
  )
}

const ModalityFilter = (props) => {
  const classes = useStyles()
  const { setFilter } = useFilter()

  return (
    <Filter {...props}>
      <TextInput
        label="Search"
        source="q"
        alwaysOn
        className={classes.textInput}
        onChange={(e) => {
          setFilter({ q: e.target.value })
        }}
      />
      <ReferenceInput
        label="Modality Type"
        source="type"
        reference="modalities/tube-analysis"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="Filter Active/Inactive"
        source="active"
        alwaysOn
        className={classes.selectInput}
        choices={[
          { id: 1, name: 'Only Active' },
          { id: 0, name: 'Only Inactive' },
          { id: 2, name: 'Show All' },
        ]}
      />
    </Filter>
  )
}

const ExpectedLifeIndicator = (modality) => {
  const classes = useStyles()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const fetch = async () => {
    try {
      setLoading(true)
      const res = await api.modalities.fetchUsageHistory(modality.id)
      setData(res)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onOpen = () => {
    if (data) return
    fetch()
  }

  return (
    <Tooltip
      onOpen={onOpen}
      interactive
      disableFocusListener
      title={
        <div style={{ width: 300 }}>
          <Typography variant="body2">Tube Scan Seconds Stats</Typography>

          <div className={classes.tooltipContentSmall}>
            {!data ? (
              <Typography variant="subtitle1" color="primary">
                Loading data...
              </Typography>
            ) : (
              <div style={{ width: '100%', padding: 16 }}>
                <Box pb={0.5} display="flex" justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>
                    Min Scan Secs:
                  </Typography>{' '}
                  <Typography variant="body2" color="textSecondary">
                    {numbro(Number(data.minTubeScanSecs)).format({
                      average: true,
                      lowPrecision: false,
                    })}
                  </Typography>
                </Box>
                <Box pb={0.5} display="flex" justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>
                    Max Scan Secs:
                  </Typography>{' '}
                  <Typography variant="body2" color="textSecondary">
                    {numbro(Number(data.maxTubeScanSecs)).format({
                      average: true,
                      lowPrecision: false,
                    })}
                  </Typography>
                </Box>

                <Box pb={0.5} display="flex" justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>
                    Standard Deviation:
                  </Typography>{' '}
                  <Typography variant="body2" color="textSecondary">
                    {numbro(Number(data.standardDeviation)).format({
                      average: true,
                      lowPrecision: false,
                    })}
                  </Typography>
                </Box>

                <Box pb={0.5} display="flex" justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>
                    Avg for Tube Type:
                  </Typography>{' '}
                  <Typography variant="body2" color="textSecondary">
                    {numbro(Number(modality.tubeLiveAvg)).format({
                      average: true,
                      lowPrecision: false,
                    })}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>
                    Number of Tubes:
                  </Typography>{' '}
                  <Typography variant="body2" color="textSecondary">
                    {data.numTubeReplaced}
                  </Typography>
                </Box>

                <Accordion
                  elevation={0}
                  classes={{
                    root: classes.MuiAccordionRoot,
                  }}
                >
                  <AccordionSummary
                    style={{
                      padding: 0,
                    }}
                    expandIcon={<ExpandMoreIcon fontSize="small" />}
                  >
                    <Typography color="textSecondary" variant="body2">
                      Replacements
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: 'flex', flexDirection: 'column', padding: 0 }}
                  >
                    {data.replacements
                      .sort((a, b) => new Date(a.date || 0) - new Date(b.date || 0))
                      .reverse()
                      .map((replacement) => {
                        return (
                          <Box
                            id={'replacement-' + replacement.id}
                            display="flex"
                            justifyContent="space-between"
                          >
                            {replacement.status === 'Actual' ? (
                              <Fragment>
                                <Typography
                                  variant="body2"
                                  className={classes.bold}
                                  style={{ width: '40%' }}
                                >
                                  <strong>{replacement.serial}</strong>
                                </Typography>{' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '60%',
                                    justifyContent: 'space-between',
                                    marginLeft: 12,
                                  }}
                                >
                                  <Typography variant="body2" color="textSecondary">
                                    <strong>{replacement.date}</strong>
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    <strong>Current</strong>
                                  </Typography>
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Typography
                                  variant="body2"
                                  className={classes.bold}
                                  style={{ width: '40%' }}
                                >
                                  {replacement.serial}
                                </Typography>{' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '60%',
                                    justifyContent: 'space-between',
                                    marginLeft: 12,
                                  }}
                                >
                                  <Typography variant="body2" color="textSecondary">
                                    {replacement.date}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {numbro(Number(replacement.scanSecs)).format({
                                      average: true,
                                      lowPrecision: false,
                                    })}
                                  </Typography>
                                </div>
                              </Fragment>
                            )}
                          </Box>
                        )
                      })}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>
        </div>
      }
      arrow
    >
      <div className={classes.indicator}>{modality.tubeExpectedLife}</div>
    </Tooltip>
  )
}

const ChartIndicator = (modality) => {
  const classes = useStyles()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const fetch = async () => {
    try {
      setLoading(true)
      const res = await api.modalities.fetchUsageHistory(modality.id)
      const chartData = formatHistoryData(res.history || {})
      setData(chartData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onOpen = () => {
    if (data) return
    fetch()
  }

  return (
    <Tooltip
      onOpen={onOpen}
      interactive
      disableFocusListener
      title={
        <div style={{ width: 300 }}>
          <Typography variant="body2">Tube Scan Seconds History</Typography>

          <div className={classes.tooltipContent} style={{}}>
            {loading && !data ? (
              <Typography variant="subtitle1" color="primary">
                Loading chart data...
              </Typography>
            ) : (
              <UsageChart data={data} />
            )}
          </div>
        </div>
      }
      arrow
    >
      <div className={classes.indicator}>{modality.tubeScanSecs}</div>
    </Tooltip>
  )
}

const Projection = ({ data }) => {
  const quarters = (data || []).reduce((acc, item) => {
    if (item && item.tubeDateToFail) {
      const quarter = item.tubeDateToFail

      if (item.tubeCost) {
        acc[quarter] = acc[quarter] || 0
        acc[quarter] += numbro.unformat(item.tubeCost)
      }
    }

    return acc
  }, {})

  const list = Object.keys(quarters).sort((a, b) => {
    const [, y1] = a.split(' ')
    const [, y2] = b.split(' ')

    return y1 - y2
  })

  return data ? (
    <Box mt={4} mb={4} display="flex" justifyContent="space-between" alignItems="center">
      <div>
        <SectionTitle label="Quarterly Projection" />

        <Card elevation={4} style={{ minWidth: 275, marginRight: 24 }}>
          <CardContent>
            <MuiList>
              {list
                .sort((a, b) => {
                  const [x1, y1] = a.split(' ')
                  const [x2, y2] = b.split(' ')
                  a = Number(y1 + x1.replace('Q', ''))
                  b = Number(y2 + x2.replace('Q', ''))
                  return a - b
                })
                .map((key) => {
                  return (
                    <ListItem key={'quarter-item' + key}>
                      <ListItemText primary={key} />
                      <ListItemSecondaryAction>
                        <ListItemText secondary={numbro(quarters[key]).formatCurrency()} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </MuiList>
          </CardContent>
        </Card>
      </div>
      <BarChart
        title="Cost Projection"
        data={list.map((name) => ({ name, value: quarters[name] }))}
        format={(value) => numbro(value).formatCurrency()}
        formatTooltip={(value) => [numbro(value).formatCurrency(), 'Cost']}
      />
    </Box>
  ) : null
}

const CustomData = (props) => {
  const transform = (data) => {
    let tubeLiveAvgs = {}

    tubeLiveAvgs = Object.entries(data).reduce((acc, [key, item]) => {
      acc[item.tubePartNumber] = acc[item.tubePartNumber] || []

      if (item.tubeScanSecsAvg) {
        acc[item.tubePartNumber].push(item.tubeScanSecsAvg)
      }

      return acc
    }, {})

    Object.keys(data).forEach((id) => {
      const vals = tubeLiveAvgs[data[id].tubePartNumber]
      const sum = vals.reduce((a, b) => a + b, 0)
      data[id].tubeLiveAvg = sum / vals.length || 0
    })

    return data
  }

  return <Data transform={transform} {...props} />
}

const ModalityList = (props) => {
  const [data, setData] = useState([])

  return (
    <FilterProvider>
      <List
        title="Modality Tube Analysis"
        exporter={exporter}
        bulkActionButtons={false}
        basePath="/modalities/tube-analysis"
        resource="modalities/tube-analysis"
        filters={<ModalityFilter />}
        {...props}
      >
        <CustomData onData={setData}>
          <Datagrid
            size="small"
            rowStyle={(record, index) => ({
              opacity: record.timeStatusColor === 'red' ? 0.5 : 1,
            })}
          >
            <TextField label="Modality" source="displayName" />

            <FunctionField
              label="Tube"
              render={(data) => `${data.tube} (${data.tubePartNumber})`}
            />

            <FunctionField
              label="Scan Secs"
              source="tubeScanSecs"
              render={(props) => <ChartIndicator {...props} />}
            />
            <TextField label="Usage (/mo)" source="tubeScanSecsUsed" />

            <FunctionField
              label="Expected Life"
              source="tubeExpectedLife"
              render={(props) => <ExpectedLifeIndicator {...props} />}
            />

            <TextField label="Time to Fail" source="tubeTimeToFail" />
            <TextField label="Date to Fail" source="tubeDateToFail" />
            <TextField label="Cost" source="tubeCost" />
          </Datagrid>
        </CustomData>
      </List>

      <Projection data={data} />
    </FilterProvider>
  )
}

export default ModalityList
