import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
// import SettingsIcon from '@material-ui/icons/Settings';
// import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery } from '@material-ui/core'
import { DashboardMenuItem, MenuItemLink, usePermissions } from 'react-admin'

import SubMenu from './SubMenu'
import { ModalityDashboardIcon } from '../dashboards/modality'
import modalities from '../modalities'
import modalityMap from '../modality-map'
import modalityTubeAnalysis from '../modalities-tube-analysis'
import modalityCryogenAnalysis from '../modalities-cryogen-analysis'
import modalityCapexAnalysis from '../modalities-capex-analysis'
import accounts from '../accounts'
import users from '../users'
import agents from '../agents'
import simulators from '../simulators'
import utilization from '../utilization'
import financial from '../financial'

import { USER_ROLES } from '../constants'

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuModalities: false,
    // menuCustomers: false,
    menuAdministration: false
  })
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const { permissions } = usePermissions()

  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }))
  }

  const user = JSON.parse(localStorage.getItem('user'))
  const accountId = get(user, 'accountId', 0)

  return (
    <div style={{ marginTop: 20 }}>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <SubMenu
        handleToggle={() => handleToggle('menuModalities')}
        isOpen={state.menuModalities}
        sidebarIsOpen={open}
        name='Modalities'
        icon={<modalities.icon />}
        dense={dense}
      >
        <MenuItemLink
          to='/modalities/dashboard'
          primaryText='Dashboard'
          leftIcon={<ModalityDashboardIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/modalities`}
          primaryText='List'
          leftIcon={<modalities.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/modalities/map`}
          primaryText='Map'
          leftIcon={<modalityMap.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/modalities/tube-analysis`}
          primaryText='Tube Analysis'
          leftIcon={<modalityTubeAnalysis.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/modalities/cryogen-analysis`}
          primaryText='Cryogen Analysis'
          leftIcon={<modalityCryogenAnalysis.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/modalities/capex-analysis`}
          primaryText='Capex Analysis'
          leftIcon={<modalityCapexAnalysis.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to={`/utilization`}
        primaryText='Utilization'
        leftIcon={<utilization.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {accountId < 2 && (
        <MenuItemLink
          to={`/financial`}
          primaryText='Financial'
          leftIcon={<financial.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {(permissions === USER_ROLES.SUPER_ADMIN ||
        permissions === USER_ROLES.ACCOUNT_ADMIN) && (
        <SubMenu
          handleToggle={() => handleToggle('menuAdministration')}
          isOpen={state.menuAdministration}
          sidebarIsOpen={open}
          name='Administration'
          icon={<accounts.icon />}
          dense={dense}
        >
          {permissions === USER_ROLES.SUPER_ADMIN && (
            <MenuItemLink
              to={`/accounts`}
              primaryText='Accounts'
              leftIcon={<accounts.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {permissions === USER_ROLES.ACCOUNT_ADMIN && (
            <MenuItemLink
              to={`/accounts/${accountId}`}
              primaryText='Account'
              leftIcon={<accounts.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          <MenuItemLink
            to={`/users`}
            primaryText='Users'
            leftIcon={<users.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            to={`/agents`}
            primaryText='Agents'
            leftIcon={<agents.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            to={`/simulators`}
            primaryText='Simulators'
            leftIcon={<simulators.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}
      {/*<SubMenu
          handleToggle={() => handleToggle('menuCustomers')}
          isOpen={state.menuCustomers}
          sidebarIsOpen={open}
          name="pos.menu.customers"
          icon={<visitors.icon />}
          dense={dense}
      >
          <MenuItemLink
              to={`/customers`}
              primaryText={translate(`resources.customers.name`, {
                  smart_count: 2,
              })}
              leftIcon={<visitors.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          />
          <MenuItemLink
              to={`/segments`}
              primaryText={translate(`resources.segments.name`, {
                  smart_count: 2,
              })}
              leftIcon={<LabelIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          />
      </SubMenu> 

      {isXSmall && (
          <MenuItemLink
              to="/configuration"
              primaryText={translate('pos.configuration')}
              leftIcon={<SettingsIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          />
      )}*/}
      {isXSmall && logout}
    </div>
  )
}

export default Menu
