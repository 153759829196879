import { capitalCase } from 'change-case'

export const USER_ROLES = {
  SUPER_ADMIN: 1,
  ACCOUNT_ADMIN: 2,
  USER: 3,
}

export const ROLE_OPTIONS = Object.entries(USER_ROLES).map(([name, id]) => ({
  name: capitalCase(name),
  id,
}))

export const REPORT_INTERVALS = [
  {
    name: 'Daily',
    id: 'daily',
  },
  {
    name: 'Weekly',
    id: 'weekly',
  },
  {
    name: 'Monthly',
    id: 'monthly',
  },
  {
    name: 'Yearly',
    id: 'yearly',
  },
]

export const REPORT_TYPES = [
  {
    name: 'Standard',
    id: 'standard',
  },
  {
    name: 'Offline',
    id: 'offline',
  },
]

export const NOTIFICATION_PREFERENCES = [
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'sms',
    name: 'SMS',
  },
]
