import React, { Fragment, useMemo, useState } from 'react'
import { capitalCase } from 'change-case'
import {
  List,
  Filter,
  TextField,
  DateField,
  ShowButton,
  ChipField,
  Datagrid,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Resource,
  EditButton,
  FunctionField,
  CardActions,
  TabbedShowLayout,
  Tab,
  BooleanInput,
  DateTimeInput,
  SelectArrayInput,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
} from 'react-admin'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useUser } from '../hooks'
import SectionTitle from '../components/SectionTitle'
import TabbedView from '../components/TabbedView'
// import BooleanInput from '../components/BooleanInput'
import ChipInput from '../components/ChipInput'
import states from '../lib/states'
import times from '../lib/times'
import * as cons from '../constants'

const useStyles = makeStyles((theme) => ({
  title: { width: 500 },
  textInput: { width: 500 },
  latitude: { display: 'inline-block', width: 237 },
  longitude: { display: 'inline-block', width: 237, marginLeft: 25 },
  search: {
    marginLeft: theme.spacing(2),
  },
  chipInput: { width: 500, marginBottom: theme.spacing(3) },
}))

const ActionsRefresh = (props) => <CardActions />

const ReportEdit = ({ permissions, ...props }) => {
  const classes = useStyles()
  const { isSuperadmin } = useUser()
  const [recurrent, setRecurrent] = useState(false)
  const [active, setActive] = useState(false)

  return (
    <Fragment>
      <br />
      <Edit {...props} actions={<ActionsRefresh />}>
        <SimpleForm>
          {isSuperadmin && (
            <ReferenceInput
              disabled
              fullWidth
              source="accountId"
              reference="accounts"
              formClassName={classes.textInput}
            >
              <SelectInput source="name" />
            </ReferenceInput>
          )}

          <TextInput source="title" formClassName={classes.textInput} fullWidth />

          <SelectInput
            source="type"
            choices={cons.REPORT_TYPES}
            className={classes.textInput}
            fullWidth
          />

          <ReferenceArrayInput
            fullWidth
            reference="modalities"
            source="modalityIds"
            formClassName={classes.textInput}
          >
            <SelectArrayInput addLabel label="Modalities" optionText="displayName">
              <ChipField source="displayName" />
            </SelectArrayInput>
          </ReferenceArrayInput>

          {
            //   <SelectInput
            //   label="Start Time"
            //   source="reportTime"
            //   formClassName={classes.textInput}
            //   choices={times}
            //   defaultValue={'none'}
            //   fullWidth
            // />
          }

          <ChipInput
            name="recipients"
            source="recipients"
            label="Recipients"
            className={classes.chipInput}
          />

          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <BooleanInput
              label="Recurrent"
              source="recurrent"
              parse={(v) => (v ? 1 : 0)}
              format={(v) => Boolean(v)}
              onChange={(value) => setRecurrent(value)}
            />

            <BooleanInput
              label="Enabled"
              source="active"
              parse={(v) => (v ? 1 : 0)}
              format={(v) => Boolean(v)}
              onChange={(value) => setActive(value)}
            />
          </Box>

          <FormDataConsumer>
            {({ formData }) =>
              Boolean(formData.recurrent) && (
                <SelectInput
                  source="interval"
                  choices={cons.REPORT_INTERVALS}
                  className={classes.textInput}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>

          <DateField label="Last Run At" source="lastRunAt" showTime {...props} />
          <DateField label="Next Run At" source="nextRunAt" showTime {...props} />
        </SimpleForm>
      </Edit>
      <br />
      <br />
    </Fragment>
  )
}

export default ReportEdit
