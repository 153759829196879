import { get } from '../../lib/api'

const fetch = async () => {
  const { data } = await get('dashboards')
  return data
}

const fetchUrl = async (id) => {
  const { data } = await get(`dashboards/${id}?email=arman@or-tracker.com&sessionName=abc`)
  return data
}

export default {
  fetch,
  fetchUrl,
}
