import React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput, required, CheckboxGroupInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import { useUser } from '../hooks'
import { SectionTitle, PhoneNumberInput } from '../components'
import { ROLE_OPTIONS, USER_ROLES, NOTIFICATION_PREFERENCES } from '../constants'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 },
  latitude: { display: 'inline-block', width: 237 },
  longitude: { display: 'inline-block', width: 237, marginLeft: 25 },
}

const useStyles = makeStyles(styles)

const UserEdit = (props) => {
  const { isSuperadmin } = useUser()

  const roles = isSuperadmin()
    ? ROLE_OPTIONS
    : ROLE_OPTIONS.filter((role) => role.id !== USER_ROLES.SUPER_ADMIN)

  // NOTIFICATION_PREFERENCES

  const classes = useStyles()
  return (
    <Edit {...props}>
      <SimpleForm>
        <SectionTitle label="User details" />
        <TextInput source="firstName" formClassName={classes.textInput} fullWidth />
        <TextInput source="lastName" formClassName={classes.textInput} fullWidth />
        <TextInput source="email" formClassName={classes.textInput} fullWidth />
        <PhoneNumberInput
          source="phone"
          label="Phone"
          formClassName={classes.textInput}
          fullWidth
          validate={[required()]}
        />

        <SelectInput
          label="Role"
          source="roleId"
          formClassName={classes.textInput}
          choices={roles}
          fullWidth
        />

        <CheckboxGroupInput
          label="Notification preference"
          source="config.notifications"
          choices={NOTIFICATION_PREFERENCES}
        />
      </SimpleForm>
    </Edit>
  )
}

export default UserEdit
