
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

import { PartsMenu } from './PartsMenu'
import { PartsModal } from './PartsModal'
import { demoParts } from './partsData'

const styles = {
  table: { minWidth: 650 },
  firstCol: { width: 200 },
}
const useStyles = makeStyles(styles)

export const PartsPanel = ({ id }) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  // const { data: item } = useQuery({
  //   type: 'getOne',
  //   resource: 'modalities/settings',
  //   payload: { id },
  // })

  // const [mutate, { loading }] = useMutation()

  // const update = (data) =>
  //   mutate({
  //     type: 'update',
  //     resource: `modalities/settings`,
  //     payload: { id, data },
  //   })


  // const onUpdate = ([level, key, value]) => {
  //   update({
  //     thresholds: {
  //       ...thresholds,
  //       [level]: {
  //         ...thresholds[level],
  //         [key]: value.map(Number),
  //       },
  //     },
  //   })
  // }

  return (
    <Box>
      <PartsModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.firstCol} align="left" >
                <span>Part</span>
              </TableCell>
              <TableCell align="right">
                <span>Part #</span>
              </TableCell>
              <TableCell align="right">
                <span>Serial</span>
              </TableCell>
              <TableCell align="right">
                <span>Last Changed</span>
              </TableCell>
              <TableCell align="center">
                <span>Actions</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {demoParts.map(part => {
              return (
                <TableRow key={part.name}>
                  <TableCell component="th" scope="row" className={classes.firstCol} >
                    {part.name}
                  </TableCell>
                  <TableCell align="right">
                    {part.number}  
                  </TableCell>
                  <TableCell align="right">
                    {part.serial}
                  </TableCell>
                  <TableCell align="right">
                    {part.lastChanged}
                  </TableCell>
                  <TableCell align="center">
                    <PartsMenu openModal={() => setModalOpen(true)} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}