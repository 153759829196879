import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  EditButton,
} from 'react-admin'

const AgentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const AgentList = (props) => (
  <List filters={<AgentFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="description" />
      <ReferenceField label="Account" source="accountId" reference="accounts">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export default AgentList
