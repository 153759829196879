import MemoryIcon from '@material-ui/icons/Memory'
import List from './List'
import Edit from './Edit'
import Create from './Create'

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: MemoryIcon
}
