import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

const TreemapChart = ({ id, title, data }) => {
  useEffect(() => {
    let chart = am4core.create(id, am4charts.TreeMap)

    chart.fontSize = 12
    chart.dataFields.value = 'value'
    chart.dataFields.name = 'name'
    chart.dataFields.children = 'children'
    chart.colors.step = 0

    chart.padding(10, 10, 10, 10)
    chart.data = data

    /* Configure top-level series */
    var level1 = chart.seriesTemplates.create('0')
    var level1_column = level1.columns.template
    level1_column.column.cornerRadius(4, 4, 4, 4)
    level1_column.fillOpacity = 1
    level1_column.stroke = am4core.color('#fff')
    level1_column.strokeWidth = 2
    level1_column.strokeOpacity = 1

    let level1_bullet = level1.bullets.push(new am4charts.LabelBullet())
    level1_bullet.locationY = 0.5
    level1_bullet.locationX = 0.5
    level1_bullet.label.text = '{name}'
    level1_bullet.label.fill = am4core.color('#fff')
  }, [data])

  console.log('TREEMAP CHART DATA', data)

  return (
    <Card>
      {data.length === 0 && <LinearProgress />}
      <CardContent>
        <div style={{ width: '100%', paddingTop: 0 }}>
          <Typography align="center">{title}</Typography>
          <div id={id} style={{ width: '100%', marginTop: 14, height: 250 }}></div>
        </div>
      </CardContent>
    </Card>
  )
}

export default TreemapChart
