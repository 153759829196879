import React from 'react'
import {
  // Show,
  // ShowButton,
  // SimpleShowLayout,
  // RichTextField,
  // DateField,
  // List,
  // Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  // ReferenceInput,
  // SelectInput,
  // ReferenceField,
  // TextField,
  // EditButton,
  // Filter,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import SectionTitle from '../components/SectionTitle'
import states from '../lib/states'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 },
}

const useStyles = makeStyles(styles)

const AccountCreate = ({ permissions, ...props }) => {
  const classes = useStyles()
  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label="Create account" />
        <TextInput source="name" formClassName={classes.textInput} fullWidth />
        <TextInput source="address1" formClassName={classes.textInput} fullWidth />
        <TextInput source="address2" formClassName={classes.textInput} fullWidth />
        <TextInput source="city" formClassName={classes.textInput} fullWidth />
        <SelectInput
          label="State"
          source="state"
          formClassName={classes.textInput}
          choices={states}
          fullWidth
        />
        <TextInput source="zip" formClassName={classes.textInput} fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default AccountCreate
