import { isString, isNumber } from 'lodash'
import { capitalCase } from 'change-case'

export const toFixedNumber = (num, digits, base) => {
  if (!isNumber(num)) return num
  var pow = Math.pow(base || 10, digits)
  return Math.round(num * pow) / pow
}

export const toId = (id) => (id ? `/${id}` : '')

export const toObjectArray = (value) => {
  return (value || []).map((v) => (isString(v) ? { value: v, label: capitalCase(v) } : v))
}

export const formatHistoryData = (data) => {
  return Object.entries(data).reduce((acc, [name, items]) => {
    const value = items.reduce((a, { value }) => a + value, 0) / items.length
    acc.push({ name, value: toFixedNumber(value, 2) })
    return acc
  }, [])
}
