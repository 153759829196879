import React from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts'

const UsageChart = ({ data }) => {
  return (
    <LineChart
      width={260}
      height={200}
      data={data}
      margin={{
        top: 24,
        right: 24,
        left: -8,
        bottom: 16,
      }}
    >
      <XAxis tickLine={false} tickMargin={16} tickSize={3} dataKey="name" angle={-60} />
      <YAxis tickLine={false} width={70} />
      <Tooltip />
      <Line strokeWidth={2} type="monotone" dataKey="value" stroke="#283593" activeDot={{ r: 8 }} />
    </LineChart>
  )
}

export default UsageChart
