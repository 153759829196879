import MisIcon from '../assets/MISlogo.png'

export const fixedLocations = [
  {
    type: 'fixed',
    siteName: 'MIS HQ',
    latitude: 34.101809,
    longitude: -84.508613,
    siteAddress: '229 Arnold Mill Road, Ste 100 Woodstock, GA 30188',
    icon: MisIcon,
    url: 'https://medicalimaginggroup.com/contact-us/',
  },
  {
    type: 'fixed',
    siteName: 'MIS West',
    latitude: 36.042358,
    longitude: -115.027466,
    siteAddress: '980 American Pacific Drive, Ste 101, Henderson, NV 89014',
    icon: MisIcon,
    url: 'https://medicalimaginggroup.com/contact-us/',
  }
];
