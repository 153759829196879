import React, { useState } from 'react'
import { Admin, Resource } from 'react-admin'
import { createMuiTheme } from '@material-ui/core/styles'
import Amplify from 'aws-amplify'

import authConfig from './auth/config'
import { lightTheme } from './layout/themes'
// import userSaga from './data/sagas/user'
// import userReducer from './data/reducers/user'
import customRoutes from './routes'
import authProvider from './auth/provider'
import { provider as dataProvider } from './data'
import { Layout } from './layout'
import LoginPage from './auth/LoginPage'
import LogoutButton from './auth/LogoutButton'
import Dashboard from './dashboards/main'
import modalities from './modalities'
import accounts from './accounts'
import alerts from './alerts'
import simulators from './simulators'
import reports from './reports'
import agents from './agents'
import users from './users'
import { USER_ROLES } from './constants'
import modalitiesTubeAnalysis from './modalities-tube-analysis'
import modalitiesCryogenAnalysis from './modalities-cryogen-analysis'
import modalitiesCapexAnalysis from './modalities-capex-analysis'
import { BrandProvider, useBrand } from './hooks/useBrand'

Amplify.configure(authConfig)
// const theme = createMuiTheme(lightTheme)

const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const brand = useBrand()
  const theme = createMuiTheme(brand.theme)

  console.log('BRAND', brand.theme)

  return (
    <Admin
      theme={theme}
      layout={Layout}
      loginPage={LoginPage}
      logoutButton={LogoutButton}
      dashboard={Dashboard}
      customRoutes={customRoutes}
      dataProvider={dataProvider(user && user.accountId)}
      authProvider={authProvider(setUser)}
    >
      {permissions => [
        <Resource
          name='modalities/tube-analysis'
          {...modalitiesTubeAnalysis}
        />,
        <Resource
          name='modalities/cryogen-analysis'
          {...modalitiesCryogenAnalysis}
        />,
        <Resource
          name='modalities/capex-analysis'
          {...modalitiesCapexAnalysis}
        />,
        <Resource name='modalities' {...modalities} />,
        permissions === USER_ROLES.SUPER_ADMIN ||
        permissions === USER_ROLES.ACCOUNT_ADMIN ? (
          <Resource name='accounts' {...accounts} />
        ) : null,
        permissions === USER_ROLES.SUPER_ADMIN ||
        permissions === USER_ROLES.ACCOUNT_ADMIN ? (
          <Resource name='alerts' {...alerts} />
        ) : null,
        permissions === USER_ROLES.SUPER_ADMIN ||
        permissions === USER_ROLES.ACCOUNT_ADMIN ? (
          <Resource name='reports' {...reports} />
        ) : null,
        permissions === USER_ROLES.SUPER_ADMIN ||
        permissions === USER_ROLES.ACCOUNT_ADMIN ? (
          <Resource name='users' {...users} />
        ) : null,
        permissions === USER_ROLES.SUPER_ADMIN ||
        permissions === USER_ROLES.ACCOUNT_ADMIN ? (
          <Resource name='agents' {...agents} />
        ) : null,
        permissions === USER_ROLES.SUPER_ADMIN ||
        permissions === USER_ROLES.ACCOUNT_ADMIN ? (
          <Resource name='simulators' {...simulators} />
        ) : null,
        <Resource name='modalities/types' />,
        <Resource name='simulators/templates' />,
        <Resource name='manufacturers' />
      ]}
    </Admin>
  )
}

const BrandedApp = () => {
  return (
    <BrandProvider>
      <App />
    </BrandProvider>
  )
}

export default BrandedApp
