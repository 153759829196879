import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone'
import { capitalCase } from 'change-case'

import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ChipField,
  SelectArrayInput,
  ReferenceArrayInput,
} from 'react-admin'

import { useMutation, useQuery } from 'react-admin'

import { set, get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import SectionTitle from '../../components/SectionTitle'
import { useUser } from '../../hooks'
import RangeCell from '../../components/RangeCell'

const styles = { table: { minWidth: 650 } }
const useStyles = makeStyles(styles)

const format = (data) => {
  if (!data) {
    return {}
  }

  let res = {}
  const array = Object.entries(data).reduce((acc, [key, item]) => {
    acc.push({ ...item, key })
    return acc
  }, [])

  array.reduce((acc, { key, ...item }) => {
    Object.entries(item).forEach(([k, v]) => {
      // acc[key] = { levels: { } }
      set(res, `${k}.levels.${key}`, v)
      // set(res, `${k}.name`, )
    })
    return acc
  }, {})

  return res
}

export const CredentialsPanel = ({ id, ...props }) => {
  const classes = useStyles()
  const { isSuperadmin } = useUser()
  const { data: item } = useQuery({
    type: 'getOne',
    resource: 'modalities/credentials',
    payload: { id },
  })

  const credentials = item?.credentials || {}

  console.log('QUERIES', credentials)
  // if (item) {
  //   // data = format(item.credentials)
  // }

  // const [mutate, { loading }] = useMutation()

  // const update = (data) =>
  //   mutate({
  //     type: 'update',
  //     resource: `modalities/credentials`,
  //     payload: { id, data },
  //   })

  // const onUpdate = ([level, key, value]) => {
  //   update({
  //     thresholds: {
  //       ...thresholds,
  //       [level]: {
  //         ...thresholds[level],
  //         [key]: value.map(Number),
  //       },
  //     },
  //   })
  // }

  console.log('PROPS', props)

  return (
    <Box>
      <Edit id={id} {...props} actions={false}>
        <SimpleForm>
          <SectionTitle label="Change credentials" />
          <TextInput source="credentials.username" formClassName={classes.textInput} fullWidth />
          <TextInput
            // type="password"
            source="credentials.password"
            formClassName={classes.textInput}
            fullWidth
          />
        </SimpleForm>
      </Edit>
    </Box>
  )
}
