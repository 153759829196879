import React, { useState, useEffect, createContext, useContext, useCallback, useMemo } from 'react'
import useHash from './useHash'
import * as qs from '../lib/qs'

const SearchContext = createContext()

export const useFilter = () => {
  const context = useContext(SearchContext)

  if (!context) {
    throw new Error(`useFilter must be used within a FilterProvider`)
  }

  return context
}

const getFilter = () => {
  const filter = qs.get('filter')

  try {
    return JSON.parse(filter) || {}
  } catch (e) {
    return {}
  }
}

export const FilterProvider = (props) => {
  const [hash] = useHash()
  const [filter, updateFilter] = useState(getFilter())

  const setFilter = useCallback(
    (filter) => {
      const urlFilter = getFilter(hash)
      return updateFilter({ ...urlFilter, ...filter })
    },
    [hash]
  )

  useEffect(() => {
    setFilter(getFilter())
  }, [setFilter])

  const value = useMemo(
    () => ({
      hash,
      qs: hash.split('?')[1] || '',
      filter,
      setFilter,
      getFilter,
    }),
    [filter, hash, setFilter]
  )

  return <SearchContext.Provider value={value} {...props} />
}
