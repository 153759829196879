import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Title } from 'react-admin'

import mriIcon from '../../assets/MRIicon.png'
import analyticsIcon from '../../assets/analytics-icon.png'
import financeIcon from '../../assets/finance-analytics.png'

const useStyles = makeStyles({
    flex: {
      display: 'flex',
    },
    topStats: {
      paddingLeft: 15,
      paddingBottom: 15,
      marginBottom: 20,
      borderRadius: 10,
      background: '#F5F5F5',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    bottomCards: {
      paddingLeft: 15,
      paddingTop: 20,
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    card: {
      maxWidth: 345,
      marginRight: 40,
      marginBottom: 40,
    },
    media: {
      marginTop: 20,
      height: 140,
      backgroundSize: 'contain',
    },
  });

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'))

  return (
    <>
      {user && 
        <div style={{ padding: 10, fontSize: '1.5em' }}>
          Welcome, {user.firstName} {user.lastName}:
        </div>
      }
      <div className={`${classes.flex} ${classes.bottomCards}`}>
        <Title title="Modality Analytics Dashboard" />
        <Card className={classes.card}>
          <CardActionArea onClick={() => history.push('/modalities/dashboard')}>
            <CardMedia
              className={classes.media}
              image={mriIcon}
              title="Contemplative Reptile"
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Modality Dashboards
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Search for individual modalities and view support dashboards
            </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size="small" color="primary" onClick={() => history.push('/modalities/dashboard')}>
              View Dashboard
            </Button>
            <Button size="small" color="primary" onClick={() => history.push('/modalities')}>
              View List
            </Button>
            <Button size="small" color="primary" onClick={() => history.push('/modalities/map')}>
              View Map
            </Button>
          </CardActions>
        </Card>

        <Card className={classes.card}>
          <CardActionArea onClick={() => history.push('/utilization')}>
            <CardMedia
              className={classes.media}
              image={analyticsIcon}
              title=""
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Utilization Analytics
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Review department utilization analytics showing referral data and
                tech/physician efficiency
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => history.push('/utilization')}>
              Open
            </Button>
            {/* <Button size="small" color="primary">
            Learn More
            </Button> */}
          </CardActions>
        </Card>

        <Card className={classes.card}>
          <CardActionArea onClick={() => history.push('/financial')}>
            <CardMedia
              className={classes.media}
              image={financeIcon}
              title=""
              style={{ filter: 'opacity(0.7)' }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Financial Analytics
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Review financial reports and analytics around cost and revenue metrics
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => history.push('/financial')}>
              Open
            </Button>
            {/* <Button size="small" color="primary">
            Learn More
            </Button> */}
          </CardActions>
        </Card>
      </div>
    </>
  )
}

export default Dashboard