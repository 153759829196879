import React, { useState } from 'react'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-json'
import 'prismjs/themes/prism.css' //Example style, you can use another
import { SectionTitle } from '../components'
// import { useUser } from '../hooks'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 }
}

const json = {
  metadata: {
    ZIP: '33186',
    City: 'Miami',
    Name: 'Agenix Test Facility',
    Street: '11801 SW 90th Street',
    District: 'Med-Lab',
    Hospital: 'Agenix Test Facility',
    StreetNo: 'Suite 102',
    timezone: '-05:00',
    SystemOEM: 'Siemens',
    StationName: 'MRCTEST111',
    SystemModel: 'Symphony',
    SystemSerialNumber: 'TEST111',
    SiteIdentificationNo: '400-123704'
  }
}

const useStyles = makeStyles(styles)

const SimulatorCreate = ({ permissions, ...props }) => {
  const classes = useStyles()

  const [code, setCode] = useState(JSON.stringify(json, null, 2))

  // const { isSuperadmin } = useUser()

  console.log(code)

  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label='Create Simulator' />

        <TextInput
          source='serial'
          formClassName={classes.textInput}
          fullWidth
        />

        <ReferenceInput
          fullWidth
          source='template'
          reference='simulators/templates'
          formClassName={classes.textInput}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>

        <TextInput
          source='description'
          formClassName={classes.textInput}
          fullWidth
        />

        {
          //   isSuperadmin() && (
          //   <ReferenceInput
          //     fullWidth
          //     source='accountId'
          //     reference='accounts'
          //     formClassName={classes.textInput}
          //   >
          //     <SelectInput source='accountId' />
          //   </ReferenceInput>
          // )
        }

        {
          <FormControl
            variant='filled'
            margin='normal'
            style={{ width: '500px' }}
          >
            <InputLabel htmlFor='my-input'>Email address</InputLabel>
            <Editor
              value={code}
              textareaId='my-input'
              className={classes.textInput}
              onValueChange={code => setCode(code)}
              highlight={code => highlight(code, languages.json)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12
                // width: '100%',
                // background: 'rgba(0, 0, 0, 0.04)'
              }}
            />
          </FormControl>
        }
      </SimpleForm>
    </Create>
  )
}

export default SimulatorCreate
