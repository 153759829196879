import React from 'react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'
import DialogBase from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const Dialog = React.memo(
  ({
    title,
    classes,
    children,
    onAction,
    okLabel,
    noLabel,
    okDisabled,
    noDisabled,
    noPadding,
    noActions,
    noOk,
    noNo,
    ...props
  }) => {
    return (
      <DialogBase
        fullWidth
        TransitionComponent={Transition}
        classes={{ paper: classes.paper }}
        {...props}
      >
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent className={clsx(classes.content, { [classes.noPadding]: noPadding })}>
          {children}
        </DialogContent>

        {!noActions && (
          <DialogActions>
            {!noNo && (
              <Button color="primary" disabled={noDisabled} onClick={() => onAction({ ok: false })}>
                {noLabel}
              </Button>
            )}
            {!noOk && (
              <Button
                autoFocus
                color="primary"
                disabled={okDisabled}
                onClick={() => onAction({ ok: true })}
              >
                {okLabel}
              </Button>
            )}
          </DialogActions>
        )}
      </DialogBase>
    )
  }
)

Dialog.defaultProps = {
  okLabel: 'Accept',
  noLabel: 'Cancel',
  okDisabled: false,
  noDisabled: false,
  noPadding: false,
  noActions: false,
  noOk: false,
  noNo: false,
  classes: {},
}

export default Dialog
