import React, { useRef } from 'react'
import { isString } from 'lodash'
import { capitalCase } from 'change-case'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import BaseAutocomplete from '@material-ui/lab/Autocomplete'
import { Controller } from 'react-hook-form'
import { toObjectArray } from '../lib/utils'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const Autocomplete = ({
  className,
  name,
  rules,
  label,
  error,
  helperText,
  control,
  multiple,
  placeholder,
  disabled,
  defaultValue,
  ...props
}) => {
  const input = useRef()
  const classes = useStyles()
  defaultValue = toObjectArray(defaultValue)

  return (
    <Controller
      render={({ onChange, ...prop }) => {
        return (
          <BaseAutocomplete
            disableClearable
            getOptionLabel={(o) => o.label}
            disabled={disabled}
            ChipProps={{ onDelete: null }}
            {...prop}
            {...props}
            selectOnFocus={false}
            multiple={true}
            defaultValue={defaultValue}
            // filterSelectedOptions
            // getOptionSelected
            renderInput={(params) => (
              <TextField
                inputRef={input}
                label={label}
                className={classes.input}
                {...params}
                helperText={helperText}
                placeholder={placeholder}
                error={error}
                size="small"
                variant="filled"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
            onChange={(_, data) => onChange(data)}
          />
        )
      }}
      name={name}
      control={control}
      onChange={([_, value]) => value}
    />
  )
}

Autocomplete.defaultProps = {
  rules: {},
}

export default Autocomplete
