import React, { Fragment, useEffect, useState, cloneElement } from 'react'
import numbro from 'numbro'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  downloadCSV,
  useQuery,
  FunctionField
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ListBase from '@material-ui/core/List'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import SectionTitle from '../components/SectionTitle'
import BarChart from '../components/BarChart'
import UsageChart from '../components/UsageChart'
import Tooltip from '../components/Tooltip'
import Data from '../components/Data'
import { useFilter, FilterProvider } from '../hooks/useFilter'
import { formatHistoryData } from '../lib/utils'
import * as api from '../data'

const useStyles = makeStyles({
  textInput: { width: 400, marginRight: 10 },
  selectInput: { width: 200 },
  field: {
    display: 'flex'
  },
  actions: {
    display: 'flex'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const exporter = modalities => {
  const modalitiesForExport = modalities.map(modality => {
    const {
      dashboardIframeUrl,
      logstreamIframeUrl,
      relationshipId,
      metrics,
      relationship,
      ...modalityForExport
    } = modality
    return modalityForExport
  })

  jsonExport(
    modalitiesForExport,
    {
      headers: [
        'id',
        'displayName',
        'magnetType',
        'heLevel',
        'heBoilOff',
        'hePredictedBoilOff',
        'heRefill',
        'heTimeToRefill',
        'heDateToRefill',
        'heCapacity',
        'heCost'
      ]
    },
    (err, csv) => {
      downloadCSV(csv, 'modalities-cryogen-analysis')
    }
  )
}

const ModalityFilter = props => {
  const classes = useStyles()
  const { setFilter } = useFilter()

  return (
    <Filter {...props}>
      <TextInput
        label='Search'
        source='q'
        alwaysOn
        className={classes.textInput}
        onChange={e => {
          setFilter({ q: e.target.value })
        }}
      />
      <ReferenceInput
        label='Modality Type'
        source='type'
        reference='modalities/cryogen-analysis'
        allowEmpty
      >
        <SelectInput optionText='name' />
      </ReferenceInput>
      <SelectInput
        label='Filter Active/Inactive'
        source='active'
        alwaysOn
        className={classes.selectInput}
        choices={[
          { id: 1, name: 'Only Active' },
          { id: 0, name: 'Only Inactive' },
          { id: 2, name: 'Show All' }
        ]}
      />
    </Filter>
  )
}

const ChartIndicator = modality => {
  const classes = useStyles()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const fetch = async () => {
    try {
      setLoading(true)
      const res = await api.modalities.fetchUsageHistory(modality.id)
      const chartData = formatHistoryData(res.history || {})
      setData(chartData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onOpen = () => {
    if (data) return
    fetch()
  }

  return (
    <Tooltip
      onOpen={onOpen}
      interactive
      disableFocusListener
      title={
        <div style={{ width: 300 }}>
          <Typography variant='body2'>Helium Level History</Typography>
          <div
            className={classes.root}
            style={{
              backgroundColor: '#fff',
              borderRadius: 4,
              paddingBottom: 16,
              marginBottom: 8,
              marginTop: 8,
              minHeight: 200,
              color: '#999',
              fontSize: 14,
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {loading && !data ? (
              <Typography variant='subtitle1' color='primary'>
                Loading chart data...
              </Typography>
            ) : (
              <UsageChart data={data} />
            )}
          </div>
        </div>
      }
      arrow
    >
      <div className={classes.indicator}>{modality.heLevel}</div>
    </Tooltip>
  )
}

const Projection = ({ data }) => {
  const quarters = (data || []).reduce((acc, item) => {
    if (item && item.heDateToRefill) {
      const quarter = item.heDateToRefill

      if (item.heCost) {
        acc[quarter] = acc[quarter] || {}

        acc[quarter]['cost'] = acc[quarter]['cost'] || 0
        acc[quarter]['cost'] += numbro.unformat(item.heCost)

        acc[quarter]['liters'] = acc[quarter]['liters'] || 0
        acc[quarter]['liters'] += item.heLiters
      }
    }

    return acc
  }, {})

  const list = Object.keys(quarters)

  return data ? (
    <Box
      mt={4}
      mb={4}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <div>
        <SectionTitle label='Quarterly Projection' />

        <Card elevation={4} style={{ minWidth: 350, marginRight: 24 }}>
          <CardContent>
            <MuiList>
              {list
                .sort((a, b) => {
                  const [x1, y1] = a.split(' ')
                  const [x2, y2] = b.split(' ')
                  a = Number(y1 + x1.replace('Q', ''))
                  b = Number(y2 + x2.replace('Q', ''))
                  return a - b
                })
                .map(key => {
                  return (
                    <ListItem key={'quarter-item' + key}>
                      <ListItemText primary={key} />
                      <ListItemSecondaryAction
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <ListItemText
                          primary={numbro(
                            quarters[key]['cost']
                          ).formatCurrency()}
                        />{' '}
                        <ListItemText
                          style={{ marginLeft: 10 }}
                          secondary={
                            ' (' +
                            numbro(quarters[key]['liters']).format({
                              thousandSeparated: true,
                              mantissa: 0
                            }) +
                            ` Liters )`
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </MuiList>
          </CardContent>
        </Card>
      </div>
      <BarChart
        title='Cost Projection'
        data={list.map(name => ({ name, value: quarters[name]['cost'] }))}
        format={value => numbro(value).formatCurrency()}
        formatTooltip={value => [numbro(value).formatCurrency(), 'Cost']}
      />
    </Box>
  ) : null
}

const ModalityList = props => {
  const [data, setData] = useState([])

  return (
    <FilterProvider>
      <List
        title='Modality Cryogen Analysis'
        exporter={exporter}
        bulkActionButtons={false}
        basePath='/modalities/cryogen-analysis'
        resource='modalities/cryogen-analysis'
        filters={<ModalityFilter />}
        {...props}
      >
        <Data onData={setData}>
          <Datagrid
            size='small'
            rowStyle={(record, index) => ({
              opacity: record.timeStatusColor === 'red' ? 0.5 : 1
            })}
          >
            <TextField label='Modality' source='displayName' />
            <TextField label='Magnet Type' source='magnetType' />
            <FunctionField
              label='He Level'
              source='heLevel'
              render={props => <ChartIndicator {...props} />}
            />

            <FunctionField
              label='Predicted Boil-off (mo)'
              source='hePredictedBoilOff'
              render={props => (
                <span
                  {...props}
                  style={{
                    color:
                      props.hePredictedBoilOff > props.heBoilOffGuide
                        ? '#a50e0e'
                        : 'inherit'
                  }}
                >
                  {props.hePredictedBoilOff}
                </span>
              )}
            />

            <FunctionField
              label='Actual Boil-off (mo)'
              source='heActualBoilOff'
              render={props => (
                <span
                  style={{
                    color:
                      props.heActualBoilOff > props.heBoilOffGuide
                        ? '#a50e0e'
                        : 'inherit'
                  }}
                >
                  {props.heActualBoilOff}
                </span>
              )}
            />

            <TextField label='Anualized HE loss (L)' source='heAnualLoss' />
            <TextField label='Refill Threshold' source='heRefill' />
            <TextField label='Time to Refill' source='heTimeToRefill' />
            <TextField label='Date to Refill' source='heDateToRefill' />
            <TextField label='Capacity (L)' source='heCapacity' />
            <TextField label='Cost' source='heCost' />
          </Datagrid>
        </Data>
      </List>

      <Projection data={data} />
    </FilterProvider>
  )
}

export default ModalityList
