const format = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

export const capexData = {
  30012: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$130,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10 + 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  37068: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$130,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  72468: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$130,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  39092: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$130,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30040: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$130,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  25608: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$130,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  65087: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$70,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30111: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$55,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  11151: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$44,300',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  54127: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$120,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  23480: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$24,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30161: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$88,999',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  23146: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$21,224',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  23205: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$24,499',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  52405: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$120,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  54489: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$80,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: '0.55',
  },
  30360: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$40,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  40433: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: '$80,000',
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30879: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  45403: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  54528: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  22828: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30544: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  45380: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30275: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  14369: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  66924: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  83021: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>77% <span style={{color: 'green'}}>↑</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  73494: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'green'}}>↑</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30279: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  160009: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  30376: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  21083: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  48459: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  115537: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  45084: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  23664: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  1387: {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '206386MJMR': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '706278H660': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '702382MR750W': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '256ODCMR1': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '865525MR2': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '706278RCT': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '80598GIVCT': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '615327MR450': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  'JOI610450W': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '610831450W': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '805GIVCT': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '732706MR': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  'A9190140': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  'GEMS': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '865525CT1': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '123GEMR456': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '805GI3T': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '805988MR2': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '952924CT1': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      Tubes Used: {(Math.random()).toFixed()}<br/>
      Tube Cost: {format(Math.random() * 150000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
  '608WIMRMR0': {
    revenue: `${format(105000 + (Math.random() * 600000))}`,
    serviceCost: `${format(55000 + (Math.random() * 20000))}`,
    partsConsumption: <div style={{}}>
      Parts Ordered: {(Math.random() * 10).toFixed()}<br/>
      Parts Cost: {format(Math.random() * 70000)}<br/>
      HE Used: {(Math.random() * 5000).toFixed()}L<br/>
      HE Cost: {format(Math.random() * 15000)}<br/>
    </div>,
    uptime: <div>{Math.random().toFixed(2) * 5 + 95}% <span style={{color: 'red'}}>↓</span></div>,
    utilization: <div>65% <span style={{color: 'red'}}>↓</span></div>,
    capabilities: <div>{(Math.random() * 50).toFixed(0)}/60 CPT codes <br/> upgradable to {(40 + Math.random() * 20).toFixed(0)}</div>,
    capexScore: <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20}}>{Math.random().toFixed(2)}</div>,
  },
}