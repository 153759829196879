import React from 'react'
import { List, Datagrid, TextField, Filter, TextInput, ShowButton } from 'react-admin'

const AlertFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const AlertList = (props) => (
  <List hasCreate={false} filters={<AlertFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="trigger" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default AlertList
