import { useState, useEffect } from 'react'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'
import { dashboards } from '../data'

const useDashboard = ({ id, type, containerId = 'dashboardContainer' }) => {
  const [embedUrl, setEmbedUrl] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await dashboards.fetch()
        const id = list.find((d) => d.type === type).address
        const { EmbedUrl } = await dashboards.fetchUrl(id)
        setEmbedUrl(EmbedUrl)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [type])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { EmbedUrl } = await dashboards.fetchUrl(id)
        setEmbedUrl(EmbedUrl)
      } catch (error) {
        console.log(error)
      }
    }

    if (id) fetchData()
  }, [id])

  useEffect(() => {
    if (embedUrl) {
      const containerDiv = document.getElementById(containerId)
      const options = {
        url: embedUrl,
        container: containerDiv,
      }
      embedDashboard(options)
    }
  }, [embedUrl, containerId])

  return embedUrl
}

export default useDashboard
