import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import { useUser } from '../hooks'
import { SectionTitle } from '../components'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 },
  latitude: { display: 'inline-block', width: 237 },
  longitude: { display: 'inline-block', width: 237, marginLeft: 25 }
}

const useStyles = makeStyles(styles)

const SimulatorEdit = props => {
  const { isSuperadmin } = useUser()

  const classes = useStyles()
  return (
    <Edit {...props}>
      <SimpleForm>
        <SectionTitle label='Simulator details' />

        <TextInput
          source='serial'
          formClassName={classes.textInput}
          fullWidth
        />

        <ReferenceInput
          fullWidth
          source='template'
          reference='simulators/templates'
          formClassName={classes.textInput}
        >
          <SelectInput source='template' />
        </ReferenceInput>

        <TextInput
          source='description'
          formClassName={classes.textInput}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  )
}

export default SimulatorEdit
