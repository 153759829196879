import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import styled from 'styled-components'

export const PartsModal = ({ open, handleClose }) => {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Place Part Order Request</DialogTitle>
      <DialogContent>
        <StyledTextField
          fullWidth
          id="quantity"
          label="Quantity"
          variant="outlined"
        />
        <StyledTextField
          fullWidth
          id="itemId"
          label="Item ID"
          variant="outlined"
        />
        <StyledTextField
          fullWidth
          id="manufacturer"
          label="Manufacturer"
          variant="outlined"
          value="Siemens"
          disabled
        />
        <StyledTextField
          fullWidth
          id="model"
          label="Model"
          variant="outlined"
          value="Sensation 64"
          disabled
        />
        <StyledTextField
          fullWidth
          id="partNumber"
          label="partNumber"
          variant="outlined"
          value="12512514"
        />
        <StyledTextField
          fullWidth
          id="rev"
          label="Rev"
          variant="outlined"
          value=""
        />
        <StyledTextField
          fullWidth
          id="year"
          label="Year"
          variant="outlined"
          value=""
        />
        <StyledTextField
          fullWidth
          id="deviceType"
          label="Device Type"
          variant="outlined"
          value=""
        />
        <StyledTextField
          fullWidth
          id="notes"
          label="Notes"
          variant="outlined"
          value=""
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
        >
          Place Order
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledTextField = styled(TextField)`
  margin-top: 15px;
`