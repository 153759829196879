import React, { createContext, useContext, useEffect, useState } from 'react'
import AgenixLogo from '../assets/logo.png'
import AgenixLogo2 from '../assets/logo-agenix.png'
import OpsTrackerLogo from '../assets/OPS-Tracker-logo-white.png'
import OpsTrackerLogo2 from '../assets/OPS-Tracker-Logo.png'
import * as qs from '../lib/qs'

const brands = {
  agenix: {
    name: 'agenix',
    logo: AgenixLogo,
    logo2: AgenixLogo2,
    theme: {
      palette: {
        secondary: {
          light: '#5f5fc4',
          main: '#283593',
          dark: '#001064',
          contrastText: '#fff'
        }
      },
      overrides: {
        MuiFilledInput: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '&$disabled': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }
        }
      }
    }
  },
  opstracker: {
    name: 'opstracker',
    logo: OpsTrackerLogo,
    logo2: OpsTrackerLogo2,
    theme: {
      palette: {
        primary: {
          main: '#6d173d'
        },
        secondary: {
          light: '#5f5fc4',
          main: '#6d173d',
          dark: '#001064',
          contrastText: '#fff'
        }
      },
      overrides: {
        MuiFilledInput: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '&$disabled': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }
        }
      }
    }
  }
}

const BrandContext = createContext(brands.opstracker)

export const isAgenix = () => {
  return document.location.href.includes('.agenix')
}

export const getDefaultBrand = () => {
  return isAgenix() ? 'agenix' : 'opstracker'
}

export const getBrandFromUrl = () => {
  const brand = qs.get('brand')
  return brands[brand] ? brand : undefined
}

export const useBrand = () => {
  const context = useContext(BrandContext)
  return context
}

export const BrandProvider = ({ brand, ...props }) => {
  const defaultBrand = getDefaultBrand()
  const brandFromUrl = getBrandFromUrl()
  const [selectedBrand, setBrand] = useState(brandFromUrl || defaultBrand)

  useEffect(() => {
    if (!brand) return
    setBrand(brandFromUrl || brand)
  }, [brand])

  const value = brands[selectedBrand]

  return <BrandContext.Provider value={value} {...props} />
}
