import { get, post, del, patch } from '../../lib/api'

const fetch = async (id = {}) => {
  const { data } = typeof id === 'string' ? await get(`agents/${id}`) : await get('agents', id)
  return data
}

const fetchCommands = async () => {
  const { data } = await get('agents/commands')
  return data
}

const assign = async ({ id, modalityId, ...params }) => {
  const { data } = await post(`agents/${id}/modalities/${modalityId}`, params)
  return data
}

const unassign = async ({ id, modalityId, ...params }) => {
  const { data } = await del(`agents/${id}/modalities/${modalityId}`, params)
  return data
}

const updateConfig = async ({ id, modalityId, ...params }) => {
  const { data } = await patch(`agents/${id}/modalities/${modalityId}`, params)
  return data
}

export default {
  fetch,
  fetchCommands,
  assign,
  unassign,
  updateConfig,
}
