import React, { useState } from 'react'
import { useLogin, useNotify } from 'react-admin'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import LockIcon from '@material-ui/icons/Lock'
import styled from 'styled-components'
import { useBrand } from '../hooks/useBrand'
import brand from '../assets/logo-agenix.png'
import ModalityBackground2 from '../assets/mr2.jpg'
import ModalityBackground1 from '../assets/mr.jpg'

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  }
}))

const FORGOT_PWD = {
  OFF: 0,
  SEND_CODE: 1,
  RESET_PWD: 2
}

const LoginPage = ({ theme }) => {
  const brand = useBrand()
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConf, setNewPasswordConf] = useState('')
  const [newPwdMode, setNewPwdMode] = useState(false)
  const [forgotPwdMode, setForgotPwdMode] = useState(0)
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const login = useLogin()
  const notify = useNotify()

  const submit = async e => {
    e.preventDefault()
    setError('')
    if (forgotPwdMode === FORGOT_PWD.SEND_CODE) {
      try {
        await Auth.forgotPassword(email)
        return setForgotPwdMode(FORGOT_PWD.RESET_PWD)
      } catch (error) {
        setError(error.message)
        return console.log(error)
      }
    }

    if (forgotPwdMode === FORGOT_PWD.RESET_PWD) {
      if (newPassword !== newPasswordConf) {
        return setError('Passwords do not match.')
      }
      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword)
        setForgotPwdMode(0)
        setPassword('')
        setCode('')
        return
      } catch (error) {
        setError(error.message)
        return console.log(error)
      }
    }

    try {
      await login({ email, password, newPassword })
    } catch (err) {
      console.log(err)
      if (err.message === 'NEW_PASSWORD_REQUIRED') {
        return setNewPwdMode(true)
      }
      if (err.code === 'InvalidPasswordException') {
        setError(err.message)
      }
      notify('Invalid email or password')
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{
          backgroundImage:
            brand.name === 'agenix'
              ? `url(${ModalityBackground2})`
              : `url(${ModalityBackground1})`
        }}
      >
        {brand.name === 'agenix' ? <TopBar /> : <TopBar2 />}

        <a href='/'>
          {brand.name === 'agenix' ? (
            <Logo1 src={brand.logo2} />
          ) : (
            <Logo2 src={brand.logo} />
          )}
        </a>
        <Card
          elevation={8}
          style={{ minWidth: 300, maxWidth: 300, marginTop: '8em' }}
        >
          <Header>
            <Avatar style={{ margin: 10 }} className={classes.large}>
              <LockIcon fontSize='large' />
            </Avatar>
          </Header>
          <Form onSubmit={submit}>
            <CardContent
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 10,
                paddingTop: 0,
                width: 'calc(100% - 20px)'
              }}
            >
              {!newPwdMode && forgotPwdMode !== FORGOT_PWD.RESET_PWD ? (
                <>
                  {forgotPwdMode === FORGOT_PWD.SEND_CODE && (
                    <div style={{ marginBottom: 10, textAlign: 'center' }}>
                      Provide your email to receive a password reset code
                    </div>
                  )}
                  <TextField
                    fullWidth
                    label='Email'
                    name='email'
                    type='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{ marginBottom: 15 }}
                  />
                  {!forgotPwdMode && (
                    <TextField
                      fullWidth
                      label='Password'
                      name='password'
                      type='password'
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      style={{ marginBottom: 15 }}
                    />
                  )}
                </>
              ) : (
                <>
                  {forgotPwdMode === FORGOT_PWD.RESET_PWD && (
                    <TextField
                      fullWidth
                      label='Enter Code You Were Emailed'
                      name='code'
                      type='code'
                      value={code}
                      onChange={e => setCode(e.target.value)}
                      style={{ marginBottom: 15 }}
                    />
                  )}
                  <TextField
                    fullWidth
                    label='Enter New Password'
                    name='password'
                    type='password'
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    style={{ marginBottom: 15 }}
                  />
                  <TextField
                    fullWidth
                    label='Confirm Password'
                    name='passwordConf'
                    type='password'
                    value={newPasswordConf}
                    onChange={e => setNewPasswordConf(e.target.value)}
                    style={{ marginBottom: 15 }}
                  />
                </>
              )}
              {error && <Error>{error}</Error>}
            </CardContent>
            <CardActions style={{ width: 'calc(100% - 20px)' }}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                type='submit'
              >
                {newPwdMode
                  ? 'UPDATE'
                  : forgotPwdMode
                  ? forgotPwdMode === FORGOT_PWD.SEND_CODE
                    ? 'EMAIL CODE'
                    : 'RESET PASSWORD'
                  : 'SIGN IN'}
              </Button>
            </CardActions>
            <Button
              color='primary'
              style={{ textDecoration: 'none' }}
              onClick={() => {
                setForgotPwdMode(prev =>
                  prev === FORGOT_PWD.SEND_CODE || prev === FORGOT_PWD.RESET_PWD
                    ? FORGOT_PWD.OFF
                    : FORGOT_PWD.SEND_CODE
                )
                setError('')
              }}
            >
              {!forgotPwdMode ? 'Forgot Password?' : '< Back to Sign in'}
            </Button>
          </Form>
        </Card>
      </Container>
    </ThemeProvider>
  )
}

const Container = styled.div`
  height: 1px;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-repeat: no-repeat;
  position: relative;
`

const TopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 90px;
  width: 100%;
`
const TopBar2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 80px;
  width: 100%;
`

const Logo1 = styled.img`
  position: absolute;
  top: 1em;
  left: 1em;
  width: 200px;
`

const Logo2 = styled.img`
  position: absolute;
  top: 1em;
  left: 1em;
  width: 300px;
`

const Header = styled.div`
  margin: 0.5em;
  display: flex;
  justify-content: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`

export default LoginPage
