import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import ArrowIcon from '@material-ui/icons/ArrowForward'
import { Title } from 'react-admin'
import MapGL, { Popup, NavigationControl, FullscreenControl, ScaleControl } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import Pins from './pins'
import CityInfo from './locationInfo'
import { modalities } from '../data'
import { fixedLocations } from './fixedLocations'

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

const fullscreenControlStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
}

const navStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px',
}

const scaleControlStyle = {
  position: 'absolute',
  bottom: 36,
  left: 0,
  padding: '10px',
}

const ModalityMap = () => {
  const [viewport, setViewport] = useState({
    // latitude: 37.785164,
    // longitude: -100,
    // zoom: 3.5,
    latitude: 36.14,
    longitude: -115.178,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  })
  const [popupInfo, setPopupInfo] = useState(null)
  const [locations, setLocations] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    ;(async () => {
      setLocations(await modalities.fetch())
    })()
  }, [])

  const fetchModalities = async () => {
    const filter = search ? { filter: encodeURIComponent(JSON.stringify({ q: search })) } : {}
    const results = await modalities.fetch(filter)
    setLocations(results || [])
  }

  const updateViewport = (viewport) => {
    setViewport(viewport)
  }

  const onClickMarker = (city) => {
    setPopupInfo(city)
  }

  const renderPopup = () =>
    popupInfo && (
      <Popup
        tipSize={5}
        anchor="top"
        longitude={popupInfo.longitude}
        latitude={popupInfo.latitude}
        closeOnClick={false}
        onClose={() => setPopupInfo(null)}
      >
        <CityInfo info={popupInfo} />
      </Popup>
    )

  return (
    <div style={{ height: 'calc(100vh - 70px)' }}>
      <Title title="Modality Map View" />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="outlined"
          placeholder="Search..."
          style={{ width: 300, marginRight: 10 }}
          onKeyPress={(e) => (e.key === 'Enter' ? fetchModalities() : null)}
        />
        <IconButton onClick={fetchModalities}>
          <ArrowIcon />
        </IconButton>
      </div>
      <MapGL
        {...viewport}
        width="100%"
        height="calc(100% - 70px)"
        mapStyle="mapbox://styles/mapbox/dark-v10"
        // mapStyle="mapbox://styles/mapbox/streets-v10"
        onViewportChange={updateViewport}
        mapboxApiAccessToken={TOKEN}
      >
        <Pins
          data={[...locations, ...fixedLocations]}
          onClick={onClickMarker}
          zoom={viewport.zoom}
        />

        {renderPopup()}

        <div style={fullscreenControlStyle}>
          <FullscreenControl />
        </div>
        <div style={navStyle}>
          <NavigationControl />
        </div>
        <div style={scaleControlStyle}>
          <ScaleControl />
        </div>

        {/* <ControlPanel containerComponent={this.props.containerComponent} /> */}
      </MapGL>
    </div>
  )
}

export default ModalityMap
