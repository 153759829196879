import React, { useEffect, useState } from 'react'
import JsonViewer from 'react-json-view'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}))

const JsonView = ({ disabled, data, onSubmit }) => {
  const classes = useStyles()
  const [value, setValue] = useState([])

  useEffect(() => {
    setValue(data)
  }, [data])

  const onReset = () => {
    setValue(data)
  }

  return (
    <Box mt={3}>
      <div>
        {/* just pass in your JSON to the src attribute */}
        <JsonViewer
          sortKeys
          style={{ padding: '0', backgroundColor: 'white' }}
          src={value}
          collapseStringsAfterLength={80}
          onEdit={(e) => {
            setValue(e.updated_src)

            if (e.new_value == 'error') {
              return false
            }
          }}
          onDelete={(e) => {
            setValue(e.updated_src)
          }}
          onAdd={(e) => {
            setValue(e.updated_src)

            if (e.new_value == 'error') {
              return false
            }
          }}
          quotesOnKeys={false}
          displayArrayKey={false}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
          name={'commands'}
          theme={{
            base00: 'white',
            base01: 'rgba(0, 0, 0, 0.04)',
            base02: '#f5f5f5',
            base03: '#444',
            base04: 'purple',
            base05: '#444',
            base06: '#444',
            base07: '#444',
            base08: '#444',
            base09: '#283593',
            base0A: '#283593',
            base0B: '#283593',
            base0C: '#283593',
            base0D: '#283593',
            base0E: '#283593',
            base0F: '#283593',
          }}
        />
      </div>

      <Box mt={4} align="right" display="flex" justifyContent="space-between">
        <Button
          disabled={value === data || disabled}
          size="medium"
          color="primary"
          onClick={onReset}
        >
          Reset
        </Button>

        <Button
          disabled={value === data || disabled}
          size="medium"
          color="primary"
          variant="contained"
          onClick={() => onSubmit(value)}
        >
          <SaveIcon fontSize="small" className={classes.buttonIcon} />
          Save
        </Button>
      </Box>
    </Box>
  )
}

JsonView.defaultProps = {
  data: [],
  disabled: false,
  onSubmit: () => {},
}

export default JsonView
