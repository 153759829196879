import React, { Fragment } from 'react'
import {
  ChipField,
  Edit,
  SimpleForm,
  TextInput,
  CardActions,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  CheckboxGroupInput,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import SectionTitle from '../components/SectionTitle'
import { NOTIFICATION_PREFERENCES } from '../constants'
import { useUser } from '../hooks'

const useStyles = makeStyles((theme) => ({
  title: { width: 500 },
  textInput: { width: 500 },
  latitude: { display: 'inline-block', width: 237 },
  longitude: { display: 'inline-block', width: 237, marginLeft: 25 },
  search: {
    marginLeft: theme.spacing(2),
  },
  chipInput: { width: 500, marginBottom: theme.spacing(3) },
}))

const ActionsRefresh = (props) => <CardActions />

const rules = [
  { id: 'GY', name: 'Green to Yellow' },
  { id: 'GR', name: 'Green to Red' },
  { id: 'YG', name: 'Yellow to Green' },
  { id: 'YR', name: 'Yellow to Red' },
  { id: 'RG', name: 'Red to Green' },
  { id: 'RY', name: 'Red to Yellow' },
  { id: 'A', name: 'Any' },
]

const triggers = [
  { id: 'health', name: 'Health Status' },
  { id: 'time', name: 'Last Reported Time' },
]

const ReportEdit = ({ permissions, ...props }) => {
  const classes = useStyles()
  const { isSuperadmin } = useUser()

  return (
    <Fragment>
      <br />
      <Edit {...props} actions={<ActionsRefresh />}>
        <SimpleForm>
          <SectionTitle label="Alert details" />

          {isSuperadmin && (
            <ReferenceInput
              disabled
              fullWidth
              source="accountId"
              reference="accounts"
              formClassName={classes.textInput}
            >
              <SelectInput source="name" />
            </ReferenceInput>
          )}

          <TextInput source="title" formClassName={classes.textInput} fullWidth />

          <ReferenceArrayInput
            fullWidth
            reference="modalities"
            source="modalityIds"
            formClassName={classes.textInput}
          >
            <SelectArrayInput addLabel label="Modalities" optionText="displayName">
              <ChipField source="displayName" />
            </SelectArrayInput>
          </ReferenceArrayInput>

          <ReferenceArrayInput
            fullWidth
            reference="users"
            source="userIds"
            formClassName={classes.textInput}
          >
            <SelectArrayInput addLabel label="Users to notify" optionText="name">
              <ChipField source="name" />
            </SelectArrayInput>
          </ReferenceArrayInput>

          <SelectInput
            fullWidth
            label="Alert Trigger"
            defaultValue="health"
            choices={triggers}
            source="trigger"
            formClassName={classes.textInput}
          />

          <SelectArrayInput
            label="Transition Rules"
            source="transitions"
            choices={rules}
            className={classes.textInput}
          />

          <CheckboxGroupInput
            label="Notification preferences"
            source="notifications"
            choices={NOTIFICATION_PREFERENCES}
          />
        </SimpleForm>
      </Edit>

      <br />
      <br />
    </Fragment>
  )
}

export default ReportEdit
