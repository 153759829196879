import React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useBrand } from '../hooks/useBrand'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
})

const CustomAppBar = props => {
  const classes = useStyles()
  const brand = useBrand()

  return (
    <AppBar {...props}>
      <img
        src={brand.logo}
        style={{
          width: brand.name === 'agenix' ? 100 : 200,
          marginBottom: brand.name === 'agenix' ? 5 : 0,
          marginRight: 20
        }}
        alt='logo'
      />
      {/* <span className={classes.spacer} /> */}
      <Typography
        variant='h6'
        color='inherit'
        className={classes.title}
        id='react-admin-title'
      />

      <span className={classes.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
