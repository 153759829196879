import React, { Fragment, useState, useEffect } from 'react'
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  ReferenceInput
} from 'react-admin'
import { uniqBy, isString } from 'lodash'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useForm, Controller } from 'react-hook-form'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Select from '@material-ui/core/Select'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useUser } from '../hooks'
import * as api from '../data'
import { SectionTitle } from '../components'
import JsonView from '../components/JsonView'
import MultiSelect from '../components/Autocomplete'
import Dialog from '../components/Dialog'
import { toObjectArray } from '../lib/utils'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  item: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  action: {
    marginRight: theme.spacing(-2)
  },
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    // width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

const CONSUMERS = ['agenix', 'logstash']
const STREAMS = ['sensor::data', 'utilization::data', 'log::data']

const ConfigForm = ({ disabled, data, onSubmit }) => {
  const classes = useStyles()

  const defaultValues = {
    host: data.host || '',
    cron: data.cron || '',
    type: data.type || '',
    accessmode: data.accessmode || '',
    consumers: toObjectArray(data.consumers || CONSUMERS),
    streams: toObjectArray(data.streams || STREAMS)
  }

  const { handleSubmit, control, formState, reset } = useForm({ defaultValues })
  const { isDirty, isValid } = formState

  const onSubmitForm = data => {
    if (data.consumers) {
      data.consumers = data.consumers.map(consumer => {
        return !isString(consumer) ? consumer.value : consumer
      })
    }

    if (data.streams) {
      data.streams = data.streams.map(stream => {
        return !isString(stream) ? stream.value : stream
      })
    }

    onSubmit(data)
  }

  const onReset = () => {
    reset(defaultValues)
  }

  return (
    <Fragment>
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.input}
        name='serial'
        fullWidth
        size='small'
        variant='filled'
        label='Serial Number'
        disabled
        value={data.serial || ''}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.input}
        name='brand'
        fullWidth
        size='small'
        variant='filled'
        label='Brand'
        disabled
        value={data.brand || ''}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.input}
        name='modality'
        fullWidth
        size='small'
        variant='filled'
        label='Modality'
        disabled
        value={data.modality || ''}
      />

      <Controller
        name='type'
        control={control}
        defaultValue={data.type || null}
        render={({ onChange, value }) => (
          <TextField
            InputLabelProps={data.type ? { shrink: true } : {}}
            className={classes.input}
            fullWidth
            size='small'
            variant='filled'
            label='Type'
            onChange={onChange}
            value={value || ''}
          />
        )}
      />

      {
        //   <MultiSelect
        //   label="Consumers"
        //   name="consumers"
        //   control={control}
        //   defaultValue={data.consumers || CONSUMERS}
        //   options={[
        //     { value: 'logstash', label: 'Logstash' },
        //     { value: 'agenix', label: 'Agenix' },
        //   ]}
        // />
      }

      <Controller
        name='host'
        control={control}
        defaultValue={data.host || null}
        render={({ onChange, value }) => (
          <TextField
            InputLabelProps={data.host ? { shrink: true } : {}}
            className={classes.input}
            fullWidth
            size='small'
            variant='filled'
            label='Host Url'
            onChange={onChange}
            value={value || ''}
          />
        )}
      />

      {
        //   data.brand === 'siemens' && (
        //   <Controller
        //     name="accessmode"
        //     multiple
        //     control={control}
        //     defaultValue={data.accessmode || null}
        //     render={({ onChange, value }) => (
        //       <TextField
        //         InputLabelProps={data.accessmode ? { shrink: true } : {}}
        //         className={classes.input}
        //         fullWidth
        //         size="small"
        //         variant="filled"
        //         label="Access Mode"
        //         onChange={onChange}
        //         value={value || ''}
        //       />
        //     )}
        //   />
        // )
      }

      {data.brand === 'siemens' && (
        <Controller
          name='accessmode'
          multiple
          control={control}
          defaultValue={data.accessmode || null}
          render={({ onChange, value }) => (
            <FormControl
              fullWidth
              size='small'
              variant='filled'
              className={classes.formControl}
            >
              <InputLabel htmlFor='accessmode'>Access Mode</InputLabel>
              <Select id='accessmode' value={value} onChange={onChange}>
                <MenuItem value='-1'>Local</MenuItem>
                <MenuItem value='2'>Remote</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      )}

      <Box mt={4} align='right' display='flex' justifyContent='space-between'>
        <Button
          disabled={!isDirty || !isValid}
          size='medium'
          color='primary'
          onClick={onReset}
        >
          Reset
        </Button>

        <Button
          size='medium'
          color='primary'
          variant='contained'
          onClick={handleSubmit(onSubmitForm)}
          disabled={!isDirty || !isValid || disabled}
        >
          <SaveIcon fontSize='small' className={classes.buttonIcon} />
          Save
        </Button>
      </Box>
    </Fragment>
  )
}

ConfigForm.defaultProps = {
  onReset: () => {},
  disabled: false
}

const getAdditionalCommands = (list, config) => {
  let { modality = '', brand = '', commands: cmds = [] } = config || {}
  modality = modality.toUpperCase()
  brand = brand.toLowerCase()

  const commands = list[modality] || {}

  return (commands[brand] || [])
    .filter(cmd => {
      if (cmds.find(c => c.cmd === cmd.cmd)) {
        return false
      }

      return true

      // return !cmd.default
    })
    .map(command => ({ value: command.cmd, ...command }))
}

const AgentEdit = props => {
  const { id } = props
  const classes = useStyles()
  const [agent, setAgent] = useState({})
  const [search, setSearch] = useState(null)
  const [command, setCommand] = useState(null)
  const [options, setOptions] = useState([])
  const [modalities, setModalities] = useState([])
  const [commands, setCommands] = useState([])
  const [selected, setSelected] = useState(null)
  const [removeConfirmation, setRemoveConfirmation] = useState(false)
  const [removeModalityId, setRemoveModalityId] = useState(null)
  const [savingConfig, setSavingConfig] = useState(false)
  const [savingCommands, setSavingCommands] = useState(false)
  const [loadingAgent, setLoadingAgent] = useState(false)
  const { isSuperadmin } = useUser()

  const { accountId } = agent || {}
  const searchOptions = options.filter(
    option => !modalities.find(m => m.id === option.id)
  )
  const sort = (a, b) =>
    a?.displayName?.toLowerCase().localeCompare(b?.displayName?.toLowerCase())
  const commandOptions = getAdditionalCommands(commands, selected)

  useEffect(() => {
    fetchCommands()
  }, [])

  useEffect(() => {
    fetch(id)
  }, [id])

  const fetch = async id => {
    try {
      setLoadingAgent(true)
      const agent = await api.agents.fetch(id)
      setAgent(agent)
      setModalities(agent.config)
    } catch (error) {}

    setLoadingAgent(false)

    try {
      const modalities = await api.modalities.fetch()
      setOptions(modalities)
    } catch (error) {}
  }

  const fetchCommands = async () => {
    try {
      const commands = await api.agents.fetchCommands()
      setCommands(commands)
    } catch (error) {}
  }

  const saveConfig = async data => {
    try {
      const agent = await api.agents.updateConfig({
        ...data,
        id,
        modalityId: selected.id
      })

      setAgent(agent)
      setModalities(agent.config)
      setSelected(agent.config.find(c => c.id === selected.id))
    } catch (error) {
      setModalities(agent.config)
    }
  }

  const onAdd = async modalityId => {
    try {
      setSearch(null)
      setModalities(list => [...list, search])
      const agent = await api.agents.assign({ id, modalityId, accountId })
      setAgent(agent)
      setModalities(agent.config)
    } catch (error) {
      setModalities(agent.config)
    }
  }

  const onRemoveModality = async modalityId => {
    const cachedSelected = { ...selected }

    try {
      setModalities(list => list.filter(item => item.id !== modalityId))
      setSelected(null)
      const agent = await api.agents.unassign({ id, modalityId, accountId })
      setAgent(agent)
      setModalities(agent.config)

      if (selected && modalityId === selected.id) {
        setSelected(null)
      }
    } catch (error) {
      setModalities(agent.config)
      setSelected(cachedSelected)
    } finally {
      setRemoveModalityId(null)
    }
  }

  const onSelect = item => {
    setSelected(item)
  }

  const onSaveConfig = async data => {
    setSavingConfig(true)
    await saveConfig(data)
    setSavingConfig(false)
  }

  const onAddCommand = async command => {
    delete command.value
    const commands = uniqBy([...selected.commands, command], 'cmd')
    setSelected({ ...selected, commands })
    setCommand(null)
    saveConfig({ commands })
  }

  const onSaveCommands = async commands => {
    setSavingCommands(true)
    await saveConfig({ commands })
    setSavingCommands(false)
  }

  const onRemoveConfirmation = ({ ok }) => {
    if (ok) onRemoveModality(removeModalityId)
    closeRemoveDialog()
  }

  const openRemoveDialog = modalitId => {
    setRemoveConfirmation(true)
    setRemoveModalityId(modalitId)
  }

  const closeRemoveDialog = () => {
    setRemoveConfirmation(false)
  }

  const renderItem = item => {
    return (
      <Paper key={`modality-${item.id}`} className={classes.paper}>
        <ListItem
          button
          selected={selected && selected.id === item.id}
          className={classes.item}
          onClick={() => onSelect(item)}
        >
          <ListItemText primary={item.displayName} />
          <ListItemSecondaryAction className={classes.action}>
            <IconButton onClick={() => openRemoveDialog(item.id)}>
              <CloseIcon color='action' fontSize='small' />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Paper>
    )
  }

  return (
    <Fragment>
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={12} md={4}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Edit {...props}>
                <SimpleForm>
                  <Box
                    fullWidth
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                    mt={1}
                  >
                    <SectionTitle label='Agent Details' />

                    <Box fullWidth mt={2}>
                      <TextInput
                        source='description'
                        formClassName={classes.textInput}
                        fullWidth
                      />

                      {isSuperadmin ? (
                        <ReferenceInput
                          fullWidth
                          source='accountId'
                          reference='accounts'
                          formClassName={classes.textInput}
                        >
                          <SelectInput source='name' />
                        </ReferenceInput>
                      ) : (
                        <ReferenceInput
                          disabled
                          fullWidth
                          source='accountId'
                          reference='accounts'
                          formClassName={classes.textInput}
                        >
                          <SelectInput source='name' />
                        </ReferenceInput>
                      )}
                    </Box>
                  </Box>
                </SimpleForm>
              </Edit>

              <Card className={classes.card}>
                {loadingAgent && <LinearProgress />}
                <CardContent style={{ padding: 24 }}>
                  <SectionTitle label='Modalities' />

                  <Box mt={3} display='flex' alignItems='center'>
                    <div style={{ width: '100%' }}>
                      <Autocomplete
                        popupIcon={null}
                        id='modality-search'
                        options={searchOptions}
                        getOptionLabel={option => option.displayName}
                        // getOptionSelected
                        value={search}
                        onChange={(_, value) => setSearch(value)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            placeholder='Search'
                          />
                        )}
                      />
                    </div>

                    <Button
                      size='medium'
                      color='primary'
                      variant='contained'
                      style={{ marginLeft: 16 }}
                      onClick={() => onAdd(search.id)}
                      disabled={!search || !search.id}
                    >
                      Add
                    </Button>
                  </Box>

                  <List className={classes.root}>
                    {modalities.sort(sort).map(renderItem)}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Card className={classes.card}>
                {savingConfig && <LinearProgress />}
                <CardContent style={{ padding: 24 }}>
                  <SectionTitle label='Modality Config' />
                  {selected ? (
                    <ConfigForm
                      key={selected.id}
                      disabled={savingConfig}
                      data={selected}
                      onSubmit={onSaveConfig}
                    />
                  ) : (
                    <Box
                      height='100%'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
                    >
                      <Typography>No modality selected</Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {selected && (
          <Grid item xs={12} sm={12} md={4}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Card className={classes.card}>
                  {savingCommands && <LinearProgress />}
                  <CardContent style={{ padding: 24 }}>
                    <SectionTitle label='Modality Commands' />

                    {commands && (
                      <Box mt={3} display='flex' alignItems='center'>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            popupIcon={null}
                            id='command-search'
                            options={commandOptions}
                            getOptionLabel={option => option.cmd}
                            // getOptionSelected
                            value={command}
                            onChange={(_, value) => setCommand(value)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                size='small'
                                variant='outlined'
                                placeholder='Search to add more commands'
                              />
                            )}
                          />
                        </div>

                        <Button
                          size='medium'
                          color='primary'
                          variant='contained'
                          style={{ marginLeft: 16 }}
                          onClick={() => onAddCommand(command)}
                          disabled={!command || !command.cmd}
                        >
                          Add
                        </Button>
                      </Box>
                    )}

                    <JsonView
                      key={selected.id}
                      data={selected.commands}
                      disabled={savingCommands}
                      onSubmit={onSaveCommands}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Dialog
        okLabel={'Remove'}
        title={'Modality remove confirmation'}
        open={removeConfirmation}
        onAction={onRemoveConfirmation}
      >
        <Typography>
          Are you sure you want to remove this modality from your agent?
        </Typography>
      </Dialog>
    </Fragment>
  )
}

export default AgentEdit
