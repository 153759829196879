import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Title } from 'react-admin'
import useWindowDimensions from '../useWindowDimensions'
import TabbedView from '../components/TabbedView'
import DonutChart from '../components/DonutChart'
import { modalities } from '../data'

// Nginx Proxy in AWS which passes basic auth params to Kibana...
const KIBANA_URL = process.env.REACT_APP_KIBANA_URL

const ModalityDashboard = ({ id }) => {
  const { width } = useWindowDimensions()
  const [modality, setModality] = useState({})

  const iframeStyle = {
    width: width > 400 ? 'calc(100vw - 260px)' : 'calc(100vw - 30px)',
    height: 'calc(100vh - 100px)',
  }

  useEffect(() => {
    ;(async () => {
      const result = await modalities.fetch(id)
      setModality(result)
    })()
  }, [id])

  return (
    <TabbedView tabLabels={['Metrics', 'Machine Logs']}>
      <Card style={{ backgroundColor: '#fafafa' }}>
        <Title title="Modality Stats" />
        <CardContent style={{ padding: 0 }}>
          {modality.displayName && (
            <Typography variant="h5" align="center" gutterBottom style={{ marginTop: 8 }}>
              {modality.displayName}, {modality.siteAddress}
            </Typography>
          )}
          {modality.dashboardIframeUrl && (
            <iframe
              title="modality dashboard"
              src={`${KIBANA_URL}${modality.dashboardIframeUrl}`}
              height="100%"
              width="100%"
              style={iframeStyle}
            ></iframe>
          )}
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: '#fafafa' }}>
        <CardContent style={{ padding: 0 }}>
          {modality.displayName && (
            <Typography variant="h5" align="center" gutterBottom style={{ marginTop: 8 }}>
              {modality.displayName}, {modality.siteAddress}
            </Typography>
          )}

          <iframe
            title="modality logs"
            src={`${KIBANA_URL}${
              modality.logstreamIframeUrl ||
              `/app/kibana#/dashboard/082d8a50-78fe-11ea-8fd3-13546dda0d0b?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A10000)%2Ctime%3A(from%3Anow-7d%2Cto%3Anow))&_a=(query:(language:kuery,query:'SystemSerialNumber:${modality.serialNumber}'))`
            }`}
            height="100%"
            width="100%"
            style={iframeStyle}
          ></iframe>
        </CardContent>
      </Card>
    </TabbedView>
  )
}

export default ModalityDashboard
