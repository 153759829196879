import { usePermissions } from 'react-admin'
import * as storage from '../lib/storage'
import { USER_ROLES } from '../constants'

const useUser = () => {
  const user = storage.get('user', {})
  const { permissions } = usePermissions()

  const isAccountAdmin = () => {
    return user.roleId === USER_ROLES.ACCOUNT_ADMIN
  }

  const isSuperadmin = () => {
    return user.roleId === USER_ROLES.SUPER_ADMIN
  }

  const isMember = () => {
    return user.roleId === USER_ROLES.USER
  }

  return { user, permissions, isAccountAdmin, isSuperadmin, isMember }
}

export default useUser
