import { useEffect } from 'react'

const useLifecycles = (mount, unmount) => {
  useEffect(() => {
    if (mount) {
      mount()
    }
    return () => {
      if (unmount) {
        unmount()
      }
    }
  }, [mount, unmount])
}

export default useLifecycles
