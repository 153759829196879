import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import TabPanel from './TabPanel'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    backgroundColor: '#fafafa',
    width: '100%',
  },
  header: {
    transform: 'translateY(-7px)',
    boxShadow: 'none',
    backgroundColor: '#fafafa',
  },
}))

const TabbedView = ({
  headerProps = {},
  tabLabels,
  tabsProps = {},
  activeTab,
  children,
  style,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(activeTab || 0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.header} {...headerProps}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          centered
          aria-label="full width tabs"
          {...tabsProps}
        >
          {tabLabels.map((label, idx) => (
            <Tab label={label} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        disableLazyLoading={true}
      >
        {children.map((Child, idx) => (
          <TabPanel value={value} index={idx} dir={theme.direction}>
            {Child}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  )
}

export default TabbedView
