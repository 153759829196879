import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  CheckboxGroupInput,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'

import { SectionTitle, PhoneNumberInput } from '../components'
import { ROLE_OPTIONS, NOTIFICATION_PREFERENCES } from '../constants'
import { useUser } from '../hooks'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 },
}

const useStyles = makeStyles(styles)

const UserCreate = ({ permissions, ...props }) => {
  const { isSuperadmin } = useUser()

  const classes = useStyles()
  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label="User details" />
        <TextInput source="firstName" formClassName={classes.textInput} fullWidth />
        <TextInput source="lastName" formClassName={classes.textInput} fullWidth />
        <TextInput source="email" formClassName={classes.textInput} fullWidth />
        {/* <TextInput source="phone" formClassName={classes.textInput} fullWidth /> */}
        <PhoneNumberInput
          source="phone"
          label="Phone"
          formClassName={classes.textInput}
          fullWidth
          validate={[required()]}
        />
        <TextInput source="tempPassword" formClassName={classes.textInput} fullWidth />

        {isSuperadmin() && (
          <ReferenceInput
            fullWidth
            source="accountId"
            reference="accounts"
            formClassName={classes.textInput}
          >
            <SelectInput source="accountId" />
          </ReferenceInput>
        )}
        <SelectInput
          source="roleId"
          choices={ROLE_OPTIONS}
          formClassName={classes.textInput}
          fullWidth
        />
        <CheckboxGroupInput
          checked="sms"
          label="Notification preference"
          source="config.notifications"
          choices={NOTIFICATION_PREFERENCES}
        />
      </SimpleForm>
    </Create>
  )
}

export default UserCreate
