import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  ShowButton,
  EditButton,
  downloadCSV,
  FunctionField,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { capexData } from './data'

const useStyles = makeStyles({
  textInput: { width: 400, marginRight: 10 },
  selectInput: { width: 200 },
  field: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const exporter = (modalities) => {
  const modalitiesForExport = modalities.map((modality) => {
    const {
      dashboardIframeUrl,
      logstreamIframeUrl,
      relationshipId,
      metrics,
      relationship,
      ...modalityForExport
    } = modality
    return modalityForExport
  })
  jsonExport(
    modalitiesForExport,
    {
      headers: [
        'id',
        'serialNumber',
        'siteIdNumber',
        'manufacturer',
        'modality',
        'model',
        'siteName',
        'heLevel',
        'heLevelTime',
        'siteAddress',
        'latitude',
        'longitude',
        'softwareVersion',
        'createdDate',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'modalities')
    }
  )
}

const ModalityFilter = (props) => {
  const classes = useStyles()

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn className={classes.textInput} />
      <ReferenceInput label="Modality Type" source="type" reference="modalities/types" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="Filter Active/Inactive"
        source="active"
        alwaysOn
        className={classes.selectInput}
        choices={[
          { id: 1, name: 'Only Active' },
          { id: 0, name: 'Only Inactive' },
          { id: 2, name: 'Show All' },
        ]}
      />
    </Filter>
  )
}

const ModalityPanel = ({ id, record, resource }) => (
  <Box
    my={2}
    mx={2}
    display="flex"
    alignItems="center"
    justifyContent="flex-start"
    flexDirection="row"
  >
    <Box mx={4}>
      <Typography variant="body2" color="textSecondary">
        Location
      </Typography>{' '}
      <Typography>{record.siteAddress}</Typography>
    </Box>
    <Box mx={4}>
      <Typography variant="body2" color="textSecondary">
        Parts Order History
      </Typography>{' '}
      <Typography><a href="#">View Order History</a></Typography>
    </Box>
    <Box mx={4}>
      <Typography variant="body2" color="textSecondary">
        Utilization Analysis
      </Typography>{' '}
      <Typography><a href="#">View Machine Utilization</a></Typography>
    </Box>
    <Box mx={4}>
      <Typography variant="body2" color="textSecondary">
        Downtime
      </Typography>{' '}
      <Typography><a href="#">View Downtime Incidents</a></Typography>
    </Box>
    <Box mx={4}>
      <Typography variant="body2" color="textSecondary">
        Capabilities
      </Typography>{' '}
      <Typography><a href="#">View CPT Coverage</a></Typography>
    </Box>
  </Box>
)

const ModalityList = (props) => {
  return (
    <>
      <div></div>
      <List
        title="Modality Capex Planning Analysis"
        filters={<ModalityFilter />}
        exporter={exporter}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid size="small" rowClick="show" expand={<ModalityPanel />}>
          <FunctionField label="Modality" render={r => `${r.siteName} ${r.model} (${r.serialNumber})`} />
          <TextField source="manufacturer" />
          <TextField source="modality" />
          <TextField source="model" />
          <FunctionField
            label="Annual Revenue"
            render={r => capexData[r.serialNumber]?.revenue}
          />
          <FunctionField
            label="Annual Service Cost"
            render={r => capexData[r.serialNumber]?.serviceCost}
          />
          <FunctionField
            label="Parts Consumption"
            render={r => capexData[r.serialNumber]?.partsConsumption}
          />
          <FunctionField
            label="Uptime"
            render={r => capexData[r.serialNumber]?.uptime}
          />
          <FunctionField
            label="Utilization"
            render={r => capexData[r.serialNumber]?.utilization}
          />
          <FunctionField
            label="Capabilities"
            render={r => capexData[r.serialNumber]?.capabilities}
          />
          <FunctionField
            label="Capex Score"
            render={r => capexData[r.serialNumber]?.capexScore}
          />
        </Datagrid>
      </List>
    </>
  )
}

export default ModalityList
