import { Auth } from 'aws-amplify'
import { users } from '../data'
import * as storage from '../lib/storage'

const authProvider = (setUser) => ({
  login: async ({ email, password, newPassword }) => {
    try {
      console.log('USER', email, password, newPassword)

      const user = await Auth.signIn(email, password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        if (!newPassword) throw new Error('NEW_PASSWORD_REQUIRED')

        const loggedUser = await Auth.completeNewPassword(user, newPassword)
        console.log(loggedUser)
      } else {
        console.log(user)
      }
      const me = await users.fetchMe()
      console.log(me)
      setUser(me)

      storage.set('user', me)
      storage.set('role', me.roleId)
      storage.set('token', user.signInUserSession.idToken.jwtToken)

      // localStorage.setItem('user', JSON.stringify(me))
      // localStorage.setItem('role', me.roleId)
      // localStorage.setItem('token', user.signInUserSession.idToken.jwtToken)
      return Promise.resolve(true)
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err)
        throw err
      }
    }
  },
  logout: () => {
    storage.del('token')
    storage.del('user')

    // localStorage.removeItem('token')
    // localStorage.removeItem('user')
    setUser(null)
    return Promise.resolve()
  },
  checkError: (error) => Promise.resolve(),
  checkAuth: () => (storage.get('token') ? Promise.resolve() : Promise.reject()),
  getPermissions: () => {
    const role = storage.get('role')
    return role ? Promise.resolve(Number(role)) : Promise.reject()
  },
})

export default authProvider
