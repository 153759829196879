import React, { Fragment } from 'react'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))

const ChipField = ({ record, source }) => {
  const classes = useStyles()
  const data = record[source]
  if (typeof data === 'undefined' || data === null || data.length === 0) {
    return <div />
  } else {
    return (
      <Fragment>
        {data.map((item) => (
          <Chip className={classes.root} label={item} key={item} />
        ))}
      </Fragment>
    )
  }
}

ChipField.defaultProps = { addLabel: true }

export default ChipField
