import React from 'react'
import Card from '@material-ui/core/Card'
import GrainIcon from '@material-ui/icons/Grain'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts'

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginTop: 40,
        marginRight: 40,
        maxWidth: 300,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
});

const colors = [
  '#ff9800',
  '#ff980090',
  '#ff980050',
  '#ff980030',
]

const ModalityCount = ({ data }) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <CardIcon Icon={GrainIcon} bgColor="#ff9800" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                  Modality Count
                </Typography>
                <PieChart
                  width={270}
                  height={200}
                  margin={{ left: -20, top:  0, bottom: 0, right: 0}}
                >
                  <Legend verticalAlign="middle" align="right" layout="vertical" />
                  <Pie
                    dataKey="value"
                    // isAnimationActive={false}
                    data={data}
                    cx={100}
                    cy={100}
                    outerRadius={50}
                    fill='#ff9800'
                    label
                  >
                    {
                      data.map((entry, idx) => 
                        <Cell key={`cell-${idx}`} fill={colors[idx]}/>)
                    }
                  </Pie>
                  <Tooltip />
                </PieChart>
            </Card>
        </div>
    );
};

export default ModalityCount;