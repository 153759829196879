import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/ViewQuilt'

import ModalityCount from './ModalityCount'
import TubeScanSecs from './TubeScanSecs'
import { getTubeScanSecondHistogram } from '../../data/api/elastic'
import { modalities } from '../../data'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  topStats: {
    paddingLeft: 15,
    paddingBottom: 15,
    marginBottom: 20,
    borderRadius: 10,
    background: '#F5F5F5',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  bottomCards: {
    paddingLeft: 15,
    paddingTop: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  card: {
    maxWidth: 345,
    marginRight: 40,
    marginBottom: 40,
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
})

const Dashboard = ({ history }) => {
  const [scanSecData, setScanSecData] = useState([])
  const [count, setCount] = useState([])
  const classes = useStyles()

  useEffect(() => {
    ;(async () => {
      const result = await getTubeScanSecondHistogram()
      if (result && result.aggregations) {
        setScanSecData(
          result.aggregations['Scan Seconds'].buckets.map((b, i) => {
            const nextThreshold = result.aggregations['Scan Seconds'].buckets[i + 1]
              ? result.aggregations['Scan Seconds'].buckets[i + 1]
              : null

            const name = nextThreshold
              ? `${b.key} - ${result.aggregations['Scan Seconds'].buckets[i + 1].key}`
              : `> ${b.key}`

            return {
              name,
              value: b.doc_count,
            }
          })
        )
      }
      const count = await modalities.fetchCountByVendorAndType()
      setCount(
        count.map((c) => ({
          name: `${c.manufacturerName} ${c.modalityType}`,
          value: c['count(*)'],
        }))
      )
    })()
  }, [])

  return (
    <div className={`${classes.flex} ${classes.topStats}`}>
      <ModalityCount data={count} />
      <TubeScanSecs data={scanSecData} />
    </div>
  )
}

export default Dashboard
export const ModalityDashboardIcon = DashboardIcon
