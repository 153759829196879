import axios from 'axios'

const API = process.env.REACT_APP_ELASTIC_URL

const getConfig = async () => {
  return {
    headers: {
      Authorization: `Basic YXJtYW46c3lzb3BzNDk0MTA0`
    }
  }
}

const getApi = baseUrl => ({
  getResource: async (name, query = '') => {
    const queryString =
      typeof query === 'object'
        ? Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&')
        : query

    try {
      const { data } = await axios.get(`${baseUrl}/${name}?${queryString}`, await getConfig())
      if (data.data) return data.data
      return data
    } catch (err) {
      console.log(err)
      throw err
    }
  },

  postResource: async (name, body) => {
    try {
      const { data } = await axios.post(`${baseUrl}/${name}`, body, await getConfig())
      return data
    } catch (err) {
      console.log(err)
      throw err
    }
  },

  putResource: async (name, body) => {
    try {
      const { data } = await axios.put(`${baseUrl}/${name}`, body, await getConfig())
      return data.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },

  patchResource: async (name, body) => {
    try {
      const { data } = await axios.patch(`${baseUrl}/${name}`, body, await getConfig())
      return data.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },

  postFormData: async (name, formData) => {
    try {
      const config = await getConfig()
      config.headers['Content-Type'] = 'multipart/form-data'

      const { data } = await axios.post(`${baseUrl}/${name}`, formData, config)
      return data.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },

  deleteResource: async (name, query = '', data = {}) => {
    console.log(name)
    const config = await getConfig()
    try {
      await axios.delete(`${baseUrl}/${name}?${query}`, { ...config, data })
      return 'success'
    } catch (err) {
      console.log(err)
      throw err
    }
  },
})

export default getApi(API)
