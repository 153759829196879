import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const LocationInfo = React.memo(({ info }) => (
  <Container>
    {info.type === 'fixed' ? (
      <Fragment>
        <b>{info.siteName}</b>
        <br />
        {info.siteAddress}
        <br />
        <br />
        {info.url && (
          <a href={info.url} target="_blank" rel="noopener noreferrer">
            open
          </a>
        )}
      </Fragment>
    ) : (
      <Fragment>
        {info.siteName} | {info.siteIdNumber}
        <br />
        {info.siteAddress}
        <br />
        <br />
        <b>Modality:</b> {info.manufacturer} {info.modality}
        <br />
        <b>Model:</b> {info.model}
        <br />
        <b>Serial #:</b> {info.serialNumber}
        <br />
        <br />
        <Link to={`/modalities/${info.id}/show`}>open</Link>
      </Fragment>
    )}
  </Container>
))

const Container = styled.div`
  font-family: Roboto, Helvetica, sans-serif;
  color: #555;
  font-size: 0.9em;
  padding: 10px;
`

export default LocationInfo
