import React from 'react'
import {
  // Show,
  // ShowButton,
  // SimpleShowLayout,
  // RichTextField,
  // DateField,
  // List,
  // Edit,
  required,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ChipField,
  SelectArrayInput,
  ReferenceArrayInput,
  // ReferenceField,
  // TextField,
  // EditButton,
  // Filter,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import SectionTitle from '../components/SectionTitle'
import { useUser } from '../hooks'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 },
  latitude: { display: 'inline-block', width: 237 },
  longitude: { display: 'inline-block', width: 237, marginLeft: 25 },
}

const useStyles = makeStyles(styles)

const ModalityCreate = ({ permissions, ...props }) => {
  const { isSuperadmin } = useUser()
  const classes = useStyles()

  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label="Create modality" />
        <TextInput
          source="siteName"
          formClassName={classes.textInput}
          // validate={[required]}
          fullWidth
        />
        <TextInput source="siteIdNumber" formClassName={classes.textInput} fullWidth />
        <TextInput source="siteAddress" formClassName={classes.textInput} fullWidth />
        <TextInput source="latitude" formClassName={classes.latitude} fullWidth />
        <TextInput source="longitude" formClassName={classes.longitude} fullWidth />

        {isSuperadmin() && (
          <ReferenceArrayInput
            fullWidth
            reference="accounts"
            source="accountIds"
            formClassName={classes.textInput}
          >
            <SelectArrayInput addLabel label="Accounts" optionText="name">
              <ChipField source="name" />
            </SelectArrayInput>
          </ReferenceArrayInput>
        )}

        <ReferenceInput
          fullWidth
          source="manufacturerId"
          reference="manufacturers"
          formClassName={classes.textInput}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          fullWidth
          source="typeId"
          reference="modalities/types"
          formClassName={classes.textInput}
        >
          <SelectInput source="type" />
        </ReferenceInput>
        <TextInput source="model" formClassName={classes.textInput} fullWidth />
        <TextInput source="serialNumber" formClassName={classes.textInput} fullWidth />
        <SelectInput
          source="active"
          choices={[{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }]}
          formClassName={classes.textInput}
          fullWidth
        />
        <TextInput source="dashboardUrl" formClassName={classes.textInput} fullWidth multiline />
      </SimpleForm>
    </Create>
  )
}

export default ModalityCreate
