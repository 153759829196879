import { stringify } from 'query-string'
import { fetchUtils } from 'react-admin'
import { Auth } from 'aws-amplify'

const createProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => {
      console.log('JSON', resource, json)
      return { data: json.data, total: json.count }
    })
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json.data,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json.data }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }

    // let refResource;
    //   const match = /_nested_(.*)_id/g.exec(params.target);
    //   if (match != null) {
    //     refResource = `${match[1]}/${params.id}/${resource}`;
    //   } else {
    //     query[`filter[${params.target}]`] = params.id;
    //     refResource = resource;
    //   }

    const url = `${apiUrl}/${resource}?${stringify(query)}`

    console.log('URL', url, query, resource)
    return httpClient(url).then(({ json }) => ({ data: json.data, total: json.count }))
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json.data })),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PATCH',
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.data.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.data.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json.data })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.data.id) })),
})

export default (accountId) => {
  const httpClient = async (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
    }
    const credentials = await Auth.currentSession()

    options.headers.set('Authorization', `Bearer ${credentials.idToken.jwtToken}`)
    return fetchUtils.fetchJson(url, options)
  }

  const dataProvider = createProvider(
    // process.env.REACT_APP_API_URL_LOCAL
    process.env.REACT_APP_API_URL,
    httpClient
  )

  return dataProvider
}
