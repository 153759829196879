import React, { useEffect, cloneElement, Fragment } from 'react'

const Data = ({ children, data, onData, transform, ...props }) => {
  data = transform(data)

  useEffect(() => {
    if (data) onData(Object.entries(data).map(([a, item]) => item))
  }, [data, onData])

  return <Fragment>{cloneElement(children, { ...props, data })}</Fragment>
}

Data.defaultProps = {
  onData: () => {},
  transform: (d) => d,
}

export default Data
