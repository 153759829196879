import { get } from '../../lib/api'

const fetchMe = async () => {
  const { data } = await get('users/me')
  return data
}

export default {
  fetchMe,
}
