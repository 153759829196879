import StorageIcon from '@material-ui/icons/Storage'
import List from './List'
import Edit from './Edit'
import Create from './Create'

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: StorageIcon,
}
