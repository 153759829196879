import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 12,
    width: 25,
    backgroundColor: 'green',
    borderRadius: 2,
    marginLeft: 2,
    marginRight: 2,
  },
  inactive: {
    opacity: 0.2,
    border: '1px solid red',
  },
}))

const Indicator = ({ color = 'green', inactive }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, inactive ? classes.inactive : null)}
      style={{ backgroundColor: color || 'inherit' }}
    />
  )
}

export default Indicator
