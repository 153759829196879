import React from 'react'
import { Marker } from 'react-map-gl'

const SIZE = 25

const Pins = React.memo(({ data, onClick, zoom }) =>
  data.map((location, index) => (
    <Marker key={`marker-${index}`} longitude={location.longitude || 0} latitude={location.latitude || 0}>
      {location.icon ? (
        <img
          src={location.icon}
          style={{ maxWidth: 36 + Number(zoom * 2), cursor: 'pointer', transform: `translateY(${-SIZE / 2}px` }}
          alt="icon"
          onClick={() => onClick(location)}
        />
      ) : (
        <svg
          height={SIZE}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: '#d00',
            stroke: 'none',
            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
          }}
          onClick={() => onClick(location)}
        >
          <g transform="translate(0 -1028.4)">
            <path d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z" fill="#e74c3c" transform="translate(0 1028.4)"/>
            <path d="m12 3c-2.7614 0-5 2.2386-5 5 0 2.761 2.2386 5 5 5 2.761 0 5-2.239 5-5 0-2.7614-2.239-5-5-5zm0 2c1.657 0 3 1.3431 3 3s-1.343 3-3 3-3-1.3431-3-3 1.343-3 3-3z" fill="#c0392b" transform="translate(0 1028.4)"/>
          </g>
        </svg>
      )} 
    </Marker>
  ))
)

export default Pins
