import React, { forwardRef, cloneElement } from 'react'
import TooltipBase from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(12),
    // padding: theme.spacing(0.5),
  },
}))

const TooltipWrapper = forwardRef(({ children, ...props }, ref) => (
  <div ref={ref}>{cloneElement(children, props)}</div>
))

const Tooltip = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <TooltipBase classes={classes} {...props}>
      <TooltipWrapper>{children}</TooltipWrapper>
    </TooltipBase>
  )
}

export default Tooltip
