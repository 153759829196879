import React from 'react';
import Card from '@material-ui/core/Card';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginRight: 40,
        marginTop: 40,
        maxWidth: 300,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
});

const colors = [
  'rgb(49, 112, 143)',
  'rgba(49, 112, 143, 0.8)',
  'rgba(49, 112, 143, 0.5)',
  'rgba(49, 112, 143, 0.3)',
]

const TubeScanSecs = ({ data }) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <CardIcon Icon={TimelapseIcon} bgColor="#31708f" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                  Tube Scan Seconds
                </Typography>
                <PieChart
                  width={240}
                  height={200}
                  margin={{ left: 50, top: -20, bottom: 0, right: 0}}
                >
                  <Pie
                    dataKey="value"
                    // isAnimationActive={false}
                    data={data}
                    cx={100}
                    cy={100}
                    outerRadius={50}
                    fill='rgb(49, 112, 143)'
                    label
                  >
                    {
                      data.map((entry, idx) => 
                        <Cell key={`cell-${idx}`} fill={colors[idx]}/>)
                    }
                  </Pie>
                  <Tooltip />
                </PieChart>
            </Card>
        </div>
    );
};

export default TubeScanSecs;