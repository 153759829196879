import React from 'react'
import TextField from '@material-ui/core/TextField'
import MaskedInput from 'react-text-mask';
import { useInput } from 'react-admin';

const PhoneMaskedInput = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
};

const PhoneNumberInput = ({ label, ...props }) => {
  const format = v => v ? v.replace('+1', '') : v
  const parse = v => '+1' + v.replace(/[^0-9.]/g, '')

  const {
    input: { name, onChange, value },
    meta: { touched, error },
    isRequired,
  } = useInput({ format, parse, ...props });

  return (
    <TextField
      variant="filled"
      fullWidth
      id={name}
      name={name}
      label={label}
      error={!!(touched && error)}
      helperText={touched && error}
      onChange={onChange}
      required={isRequired}
      value={value}
      InputProps={{
        inputComponent: PhoneMaskedInput,
      }}
      style={{ marginBottom: 20 }}
    />
  )
};

export default PhoneNumberInput