import React from 'react'
import {
  BarChart as BaseBarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const renderShape = (key) => ({ height, width, fill, x, y }) => {
  return (
    <svg x={x} y={y} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={key} x1="0%" y1="0%" x2="0%" y2={`100%`}>
          {<stop offset="0%" stopColor={fill} />}
          <stop offset="100%" stopColor={'#0ED2F7'} />
        </linearGradient>
      </defs>
      <rect fill={`url(#${key})`} width={width} height={height} />
    </svg>
  )
}

const BarChart = ({ title, data, format, formatTooltip }) => {
  return (
    <Card
      elevation={0}
      style={{
        width: '100%',
        backgroundColor: 'transparent',
        background: 'linear-gradient(60deg, #283593, #182848)',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4)',
      }}
    >
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {title && (
          <Typography
            variant="h5"
            component="h2"
            align="center"
            style={{ color: '#FFF', paddingTop: 46 }}
          >
            {title}
          </Typography>
        )}
        <ResponsiveContainer width={600} height={350}>
          <BaseBarChart data={data} margin={{ left: 42, right: 32, top: 46, bottom: 32 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#B2FEFA" />
            <XAxis width={80} dataKey="name" stroke="#B2FEFA" />
            <YAxis width={80} tickFormatter={format} stroke="#B2FEFA" />
            <Tooltip cursor={false} itemStyle={{ color: '#283593' }} formatter={formatTooltip} />
            <Bar
              stackId="1"
              name="Quarters"
              dataKey="value"
              fill="#B2FEFA"
              minPointSize={5}
              shape={renderShape('value')}
            />
          </BaseBarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}

export default BarChart
