import React, { Fragment, useMemo, useEffect, useState } from 'react'
import { capitalCase } from 'change-case'
import {
  List,
  Filter,
  TextField,
  DateField,
  ShowButton,
  ChipField,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ArrayInput,
  Resource,
  CreateButton,
  FunctionField,
  CardActions,
  TabbedShowLayout,
  Tab,
  BooleanInput,
  DateTimeInput,
  SimpleFormIterator,
  SelectArrayInput,
  ReferenceArrayInput,
  FormDataConsumer,
  ReferenceInput,
} from 'react-admin'

// import ChipInput from 'material-ui-chip-input'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import SectionTitle from '../components/SectionTitle'
import TabbedView from '../components/TabbedView'
import BooleanField from '../components/BooleanField'
import ChipInput from '../components/ChipInput'
import * as storage from '../lib/storage'
import states from '../lib/states'
import times from '../lib/times'
import * as cons from '../constants'
import { useUser } from '../hooks'

const useStyles = makeStyles((theme) => ({
  title: { width: 500 },
  textInput: { width: 500 },
  latitude: { display: 'inline-block', width: 237 },
  longitude: { display: 'inline-block', width: 237, marginLeft: 25 },
  search: { marginLeft: theme.spacing(2) },
  chipInput: { width: 500, marginBottom: theme.spacing(3) },
}))

const ActionsRefresh = (props) => <CardActions />

const ReportCreate = ({ permissions, ...props }) => {
  const [accountId, setAccountId] = useState()
  const classes = useStyles()
  const { isSuperadmin } = useUser()
  const [recurrent, setRecurrent] = useState(false)

  useEffect(() => {
    const id = storage.get('accountId')
    setAccountId(id)
  }, [])

  return (
    <Fragment>
      <br />
      <Create {...props} actions={<ActionsRefresh />}>
        <SimpleForm>
          <SectionTitle label="Report details" />

          {isSuperadmin && (
            <ReferenceInput
              fullWidth
              source="accountId"
              reference="accounts"
              formClassName={classes.textInput}
            >
              <SelectInput defaultValue={accountId} source="name" />
            </ReferenceInput>
          )}

          <TextInput source="title" formClassName={classes.textInput} fullWidth />

          <SelectInput
            source="type"
            choices={cons.REPORT_TYPES}
            className={classes.textInput}
            fullWidth
          />

          <ReferenceArrayInput
            fullWidth
            reference="modalities"
            source="modalityIds"
            formClassName={classes.textInput}
          >
            <SelectArrayInput addLabel label="Modalities" optionText="displayName">
              <ChipField source="displayName" />
            </SelectArrayInput>
          </ReferenceArrayInput>

          <ChipInput
            name="recipients"
            source="recipients"
            label="Recipients"
            className={classes.chipInput}
          />

          <DateTimeInput label="Start Date" source="date" className={classes.textInput} />

          <BooleanInput
            label="Recurrent"
            source="recurrent"
            parse={(v) => (v ? 1 : 0)}
            onChange={(value) => setRecurrent(value)}
          />

          <FormDataConsumer>
            {({ formData }) =>
              Boolean(formData.recurrent) && (
                <SelectInput
                  source="interval"
                  choices={cons.REPORT_INTERVALS}
                  className={classes.textInput}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>

          {
            //   recurrent && (
            //   <SelectInput
            //     source="interval"
            //     choices={cons.REPORT_INTERVALS}
            //     formClassName={classes.textInput}
            //     fullWidth
            //   />
            // )
          }
        </SimpleForm>
      </Create>
      <br />
      <br />
    </Fragment>
  )
}

export default ReportCreate
