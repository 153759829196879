import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import DashboardIcon from '@material-ui/icons/ViewQuilt'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'
import DonutChart from '../../components/DonutChart'
import TreemapChart from '../../components/TreemapChart'
import HealthIndicator from '../../components/HealthIndicator'
import TimeIndicator from '../../components/TimeIndicator'
import * as api from '../../data'

const useStyles = makeStyles((theme) => ({
  stretch: {
    height: '100%',
    width: '100%'
  },

  table: {
    minWidth: 650
  }
}))

function StatusTable({ data, title }) {
  const classes = useStyles()

  return (
    <Card>
      {data.length === 0 && <LinearProgress />}
      <CardContent>
        <Typography style={{ paddingBottom: 8 }} align="left">
          {title}
        </Typography>

        <TableContainer>
          <Table size="small" className={classes.table} aria-label="status table">
            <TableHead>
              <TableRow hover>
                <TableCell padding="none">
                  <Typography>Health</Typography>
                </TableCell>
                <TableCell padding="none">
                  <Typography>Site</Typography>
                </TableCell>
                <TableCell padding="none">
                  <Typography>Serial</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Vendor</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .sort((a, b) => (a.healthStatusColor < b.healthStatusColor ? -1 : 1))

                .map((item) => (
                  <TableRow
                    href={`/#/modalities/${item.id}/show`}
                    hover
                    key={'row' + item.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => (window.location.href = `/#/modalities/${item.id}/show`)}
                  >
                    <TableCell padding="none">
                      <HealthIndicator {...item} />
                    </TableCell>
                    <TableCell padding="none">{item.siteName}</TableCell>
                    <TableCell padding="none">{item.serialNumber}</TableCell>
                    <TableCell>
                      {item.manufacturer} {item.model}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

const StatusCard = ({ title, value, color }) => {
  value = value || 0

  return (
    <Card style={{ padding: 0 }}>
      {!value && value !== 0 && <LinearProgress />}
      <CardContent
        style={{
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 120,
          paddingTop: 0
        }}
      >
        <Typography style={{ paddingTop: 18 }} align="center">
          {title}
        </Typography>

        <Typography
          variant="h3"
          align="center"
          component="div"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            color: color,
            width: '100%',
            borderRadius: 8,
            marginTop: 0
          }}
        >
          <div>{value}</div>
        </Typography>
      </CardContent>
    </Card>
  )
}

const getChartData = (data) => {
  const res = data.reduce(
    (acc, item) => {
      if (item.modality === 'MRI') {
        acc.MRI.manufacturers[item.manufacturer] = acc.MRI.manufacturers[item.manufacturer] || 0
        acc.MRI.manufacturers[item.manufacturer] = acc.MRI.manufacturers[item.manufacturer] + 1
        acc.MRI.models[item.model] = acc.MRI.models[item.model] || 0
        acc.MRI.models[item.model] = acc.MRI.models[item.model] + 1

        if (item.timeStatusColor !== 'red') {
          acc.MRI[item.healthStatusColor] = acc.MRI[item.healthStatusColor] || 0
          acc.MRI[item.healthStatusColor] = acc.MRI[item.healthStatusColor] + 1
        }

        if (['yellow', 'red'].includes(item.healthStatusColor) && item.timeStatusColor !== 'red') {
          acc.MRI.status.push(item)
        }

        if (item.timeStatusColor === 'red') {
          acc.MRI.offline = acc.MRI.offline || 0
          acc.MRI.offline = acc.MRI.offline + 1
        }
      }

      if (item.modality === 'CT' || item.modality === 'PETCT') {
        acc.CT.manufacturers[item.manufacturer] = acc.CT.manufacturers[item.manufacturer] || 0
        acc.CT.manufacturers[item.manufacturer] = acc.CT.manufacturers[item.manufacturer] + 1
        acc.CT.models[item.model] = acc.CT.models[item.model] || 0
        acc.CT.models[item.model] = acc.CT.models[item.model] + 1

        if (item.timeStatusColor !== 'red') {
          acc.CT[item.healthStatusColor] = acc.CT[item.healthStatusColor] || 0
          acc.CT[item.healthStatusColor] = acc.CT[item.healthStatusColor] + 1
        }

        if (['yellow', 'red'].includes(item.healthStatusColor) && item.timeStatusColor !== 'red') {
          acc.CT.status.push(item)
        }

        if (item.timeStatusColor === 'red') {
          acc.CT.offline = acc.CT.offline || 0
          acc.CT.offline = acc.CT.offline + 1
        }
      }

      return acc
    },
    {
      MRI: {
        manufacturers: {},
        models: {},
        status: [],
        offline: null
      },
      CT: {
        manufacturers: {},
        models: {},
        status: [],
        offline: null
      }
    }
  )

  return res
}
const ModalitySample = () => {
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState({
    MRI: {
      manufacturers: {},
      models: {},
      status: [],
      offline: null
    },
    CT: {
      manufacturers: {},
      models: {},
      status: [],
      offline: null
    }
  })

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const data = await api.modalities.fetch({ filter: '{"active":1}' })
    setData(data)
  }

  useEffect(() => {
    const chartData = getChartData(data)
    setChartData(chartData)
  }, [data])

  return (
    <Box mt={2} mb={4} width="100%">
      <Grid container spacing={1}>
        <Grid item md={12} lg={6}>
          <Grid container spacing={1}>
            <Grid item md={12} lg={12}>
              <Grid container spacing={1} justify="center">
                <Grid item xs={10} sm={12} md={5} lg={6}>
                  <DonutChart
                    id="mri-manufacturers"
                    title="MRIs Manufaturers"
                    data={Object.entries(chartData.MRI.manufacturers).map(([name, value]) => ({
                      name,
                      value
                    }))}
                  />
                </Grid>

                <Grid item xs={10} sm={12} md={7} lg={6}>
                  <TreemapChart
                    id="mri-model"
                    title="MRIs Model"
                    data={Object.entries(chartData.MRI.models).map(([name, value]) => ({
                      name,
                      value
                    }))}
                  />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard title="Healthy" value={chartData.MRI.green} color="#66bb6a" />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard title="Yellow Flagged" value={chartData.MRI.yellow} color="#fdd835" />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard
                    title="Red Flagged"
                    value={chartData.MRI.red}
                    color={`${chartData.MRI.red ? '#ff5722' : '#99999940'}`}
                  />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard
                    title="Offline"
                    value={chartData.MRI.offline}
                    color={`${chartData.MRI.offline ? '#ff5722' : '#99999940'}`}
                  />
                </Grid>

                <Grid item xs={10} sm={12} md={12} lg={12}>
                  <StatusTable title={'Impacted MRIs'} data={chartData.MRI.status} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} lg={6}>
          <Grid container>
            <Grid item md={12} lg={12}>
              <Grid container spacing={1} justify="center">
                <Grid item xs={10} sm={12} md={5} lg={6}>
                  <DonutChart
                    id="ct-manufacturers"
                    title="CTs Manufacturers"
                    data={Object.entries(chartData.CT.manufacturers).map(([name, value]) => ({
                      name,
                      value
                    }))}
                  />
                </Grid>

                <Grid item xs={10} sm={12} md={7} lg={6}>
                  <TreemapChart
                    id="ct-model"
                    title="CTs Model"
                    data={Object.entries(chartData.CT.models).map(([name, value]) => ({
                      name,
                      value
                    }))}
                  />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard title="Healthy" value={chartData.CT.green} color="#66bb6a" />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard title="Yellow Flagged" value={chartData.CT.yellow} color="#fdd835" />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard
                    title="Red Flagged"
                    value={chartData.CT.red}
                    color={`${chartData.CT.red ? '#ff5722' : '#99999940'}`}
                  />
                </Grid>

                <Grid item xs={5} sm={6} md={3} lg={3}>
                  <StatusCard
                    title="Offline"
                    value={chartData.CT.offline}
                    color={`${chartData.CT.offline ? '#ff5722' : '#99999940'}`}
                  />
                </Grid>

                <Grid item xs={10} sm={12} md={12} lg={12}>
                  <StatusTable title={'Impacted CTs'} data={chartData.CT.status} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ModalitySample
export const ModalityDashboardIcon = DashboardIcon
