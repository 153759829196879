import React, { useEffect } from 'react'
import { BooleanField as BaseBooleanField } from 'react-admin'

const BooleanField = ({ record = {}, source, ...props }) => {
  const customRecord = { ...record }
  customRecord[source] = Boolean(record[source])
  const value = customRecord[source]

  useEffect(() => {
    // if (typeof props.onChange === 'function') {
    console.log('CHANGING FIELD', value)
    //props.onChange(value)
    // }
  }, [value])

  console.log('CHANGING FIELD 2', value)

  return <BaseBooleanField record={customRecord} source={source} {...props} />
}

export default BooleanField
