import React from 'react'
import { Create, SimpleForm, ReferenceInput, TextInput, SelectInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { SectionTitle } from '../components'
import { useUser } from '../hooks'

const styles = {
  title: { width: 500 },
  textInput: { width: 500 },
}

const useStyles = makeStyles(styles)

const AgentCreate = ({ permissions, ...props }) => {
  const classes = useStyles()
  const { isSuperadmin } = useUser()

  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label="Create Agent" />
        <TextInput source="description" formClassName={classes.textInput} fullWidth />
        {isSuperadmin() && (
          <ReferenceInput
            fullWidth
            source="accountId"
            reference="accounts"
            formClassName={classes.textInput}
          >
            <SelectInput source="accountId" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  )
}

export default AgentCreate
