export const demoParts = [
  {
    name: 'Tube Collimator',
    number: '8377801',
    serial: '1252',
    lastChanged: '2018',
  },
  {
    name: 'WCS water-water',
    number: 'n.a.',
    serial: 'n.a.',
    lastChanged: '12/25/2013',
  },
  {
    name: 'WCS water-air',
    number: '89024221',
    serial: '1521522',
    lastChanged: '12/25/2013',
  },
  {
    name: 'System Kit',
    number: '8377421',
    serial: '251255',
    lastChanged: '12/25/2013',
  },
  {
    name: 'PHS',
    number: '742414',
    serial: '1242421',
    lastChanged: '12/25/2013',
  },
  {
    name: 'PDC',
    number: '7151523154',
    serial: '3221',
    lastChanged: '12/25/2013',
  },
  {
    name: 'IES',
    number: 'n.a.',
    serial: '5215',
    lastChanged: '12/25/2013',
  },
  {
    name: 'HV Transformer',
    number: '824718244',
    serial: '2421',
    lastChanged: '12/25/2013',
  },
  {
    name: 'Gantry',
    number: '8215215',
    serial: '2552',
    lastChanged: '12/25/2013',
  },
  {
    name: 'Tube Assembly',
    number: '7124284',
    serial: '534341144',
    lastChanged: '12/25/2013',
  },
]