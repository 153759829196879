import ModalityIcon from '@material-ui/icons/ViewList'
import List from './List'
import Create from './Create'
import Edit from './Edit'
import Dashboard from './Dashboard'

export default {
  create: Create,
  list: List,
  show: Dashboard,
  edit: Edit,
  icon: ModalityIcon,
}
