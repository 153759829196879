import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EditIcon from '@material-ui/icons/Edit'
import TrashIcon from '@material-ui/icons/Delete'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import TagIcon from '@material-ui/icons/LocalOffer'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'

const ITEM_HEIGHT = 48

export const PartsMenu = ({ openModal }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }
  
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: 5 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      > 
        <MenuItem
          onClick={() => {
            openModal()
            handleClose()
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Order
        </MenuItem>
        <MenuItem
          onClick={() => handleClose()}
        >
          <ListItemIcon>
            <TagIcon fontSize="small" />
          </ListItemIcon>
          History
        </MenuItem>
        <MenuItem
          onClick={() => handleClose()}
        >
          <ListItemIcon>
            <FolderOpenIcon fontSize="small" />
          </ListItemIcon>
          Details
        </MenuItem>
        <StyledDivider />
        <MenuItem
          onClick={() => handleClose()}
        >
          <ListItemIcon>
            <TrashIcon fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </div>
  )
}

const StyledDivider = styled(Divider)`
  && {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`